// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_appsync_graphqlEndpoint": "https://tqg5j7cu7bdf3axdtkwcqwqypu.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-kgltzxankfhgzn474fldlwgtkq",
  Auth: {
    identityPoolId: "us-east-1:ae6eb452-cb15-421c-b0e3-0279c239235a",
    region: "us-east-1",
    userPoolId: "us-east-1_drsznwbSa",
    userPoolWebClientId: "7j3lqpgdkimaoohldj9kl7toit",
  },
};

export default awsmobile;
