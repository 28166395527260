<template>
  <Fragment>
    <v-dialog v-model="dialogIsVisible" scrollable max-width="90%">
      <v-card>
        <v-card-title class="text-center justify-center py-1">
          <h1 class="font-weight-bold text-h4 basil--text">
            {{ subject }}
          </h1>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              importList = [];
              $emit('input', false);
            "
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab>
              {{ subject }}
            </v-tab>

            <v-tab>Settings</v-tab>
          </v-tabs>
        </v-card-subtitle>

        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text
                  :title="`Import ${subject}`"
                  v-if="!importList.length"
                >
                  <simple-csv-import @get-csv="(csv) => (csvData = csv)">
                  </simple-csv-import>
                  <p>Go to the Settings Tab to configure the import data.</p>
                </v-card-text>

                <v-card-text v-else>
                  <v-container>
                    <portal to="import-main-actions">
                      <v-btn text @click="importList = []" color="warning">
                        Reset
                      </v-btn>
                      <v-btn
                        text
                        @click="uploadData"
                        color="primary"
                        :disabled="
                          !statusList.every(
                            (x) => x.status == import_statuses.PENDING
                          )
                        "
                        :loading="submitting"
                        >{{ submitting ? "Uploading..." : "Upload" }}
                      </v-btn>
                    </portal>

                    <portal-target name="row-spinner"></portal-target>

                    <v-data-iterator
                      :items="importList"
                      :items-per-page.sync="perPage"
                      :page.sync="currentPage"
                      hide-default-footer
                    >
                      <template v-slot:header>
                        <thead>
                          <tr>
                            <th
                              class="subheading font-weight-bold"
                              :class="{
                                status: col.value == 'status',
                                main: col.value != 'status',
                              }"
                              v-for="(col, idx) in tableFields"
                              :key="`header-${idx}`"
                            >
                              {{ col | columnTitle }}
                            </th>
                          </tr>
                        </thead>
                      </template>

                      <template v-slot:default="props">
                        <tbody>
                          <tr
                            v-for="(row, p_idx) in props.items"
                            :key="`row-${p_idx}`"
                          >
                            <td
                              v-for="(col, idx) in tableFields"
                              :key="`header-${idx}`"
                              :class="{
                                status: col.value == 'status',
                                main: col.value != 'status',
                              }"
                            >
                              <v-tooltip
                                right
                                color="error"
                                v-if="col.value == `status`"
                                :disabled="
                                  statusList[
                                    (currentPage - 1) * perPage + p_idx
                                  ].messages.length == 0
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    text-color="white"
                                    :color="
                                      statusList[
                                        (currentPage - 1) * perPage + p_idx
                                      ].status
                                        | filterStatusVariant(import_statuses)
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{
                                      (currentPage - 1) * perPage + p_idx + 1
                                    }}-{{
                                      statusList[
                                        (currentPage - 1) * perPage + p_idx
                                      ].status
                                    }}
                                  </v-chip>
                                </template>
                                <ol>
                                  <li
                                    v-for="(msg, i) in statusList[
                                      (currentPage - 1) * perPage + p_idx
                                    ].messages"
                                    :key="i + 'error-item'"
                                  >
                                    {{ msg }}
                                  </li>
                                </ol>
                              </v-tooltip>

                              <Fragment v-else>
                                {{ row[col.value] }}
                              </Fragment>
                            </td>
                          </tr>
                        </tbody>
                      </template>

                      <template v-slot:footer> </template>
                    </v-data-iterator>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card color="basil" flat>
                <v-card-text>
                  <upload-data-settings
                    v-model="configurationSettings"
                    :data-fields="fields"
                    :get-options="getOptions"
                    :is-viewable="tab == 1"
                  ></upload-data-settings>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <portal-target v-if="tab == 1" name="import-settings-actions">
          </portal-target> -->
          <v-row v-if="tab == 0" class="py-2">
            <portal-target name="import-main-actions"> </portal-target>
            <v-spacer></v-spacer>

            <v-btn text disabled>Rows Per Page:</v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="underline">&nbsp;{{ perPage }}&nbsp;</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <v-btn text disabled>
              {{ `Page ${currentPage} of ${numberOfPages}` }}</v-btn
            >
            <v-btn icon @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <generic-alert ref="g_alert" />
    </v-dialog>
  </Fragment>
</template>

<script>
import { debounce } from "debounce";
import { Fragment } from "vue-frag";
import { capitalCase } from "change-case";
import SimpleCsvImport from "./SimpleCsvImport.vue";
import UploadDataSettings from "./UploadDataSettings.vue";
import GenericAlert from "./GenericAlert.vue";
import importStatuses from "@/mixins/importStatuses.js";
export default {
  name: "ImportForm",
  components: { Fragment, SimpleCsvImport, UploadDataSettings, GenericAlert },
  mixins: [importStatuses],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    getOptions: {
      type: Function,
      required: true,
    },
    uploadRecord: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      csvData: null,
      tab: null,
      firstSetup: false,

      dialogIsVisible: false,
      configurationSettings: {},

      importList: [],
      statusList: [],
      files: [],
      file: {},
      status: false,

      submitting: false,
      hasErrors: false,
      /////table variables
      perPage: 15,
      currentPage: 1,
      itemsPerPageArray: [4, 8, 12],
    };
  },

  filters: {
    columnTitle(col) {
      switch (col.value) {
        case "gender":
          return "Sex";
        case "first_name":
          return "F. Name";
        case "last_name":
          return "L. Name";
        case "middle_name":
          return "M. Name";
        case "emails":
          return "Email";
        case "phones":
          return "Phone";
        case "honorific":
          return "Title";
        case "date_from":
          return "From";
        case "date_to":
          return "To";
        default:
          return col.text;
      }
    },
    filterStatusVariant(status, import_statuses) {
      switch (status) {
        case import_statuses.ERROR:
          return "red";
        case import_statuses.COMPLETE:
          return "green";
        case import_statuses.PENDING:
          return "primary";
        default:
          return "secondary";
      }
    },
    filterStatusText(status, import_statuses) {
      console.log("status", status);
      switch (status) {
        case import_statuses.ERROR:
          return "danger";
        case import_statuses.COMPLETE:
          return "success";
        default:
          return "light";
      }
    },
  },

  computed: {
    numberOfPages() {
      return Math.ceil(this.importList.length / this.perPage);
    },
    columns_map() {
      return this.$store.getters["import_settings/columns_map"];
    },

    row_no() {
      return this.$store.getters["import_settings/row_no"];
    },

    error() {
      return this.$store.state.import_settings.error;
    },

    loading() {
      return this.$store.state.import_settings.loading;
    },

    userSettings() {
      return this.$store.state.import_settings.userSettings;
    },

    tableFields() {
      console.log("fields", this.fields);
      const _fields = this.fields.map((x) => ({
        value: x.name,
        text: capitalCase(x.name),
        cols: x.name == "gender" ? 1 : undefined,
      }));
      _fields.unshift({ value: "status", text: "Status" });
      console.log("_fields", _fields);
      return _fields;
    },
  },
  watch: {
    csvData: function (val) {
      this.formatData(val);
    },
    value: function (val) {
      this.dialogIsVisible = val;
    },
    importList: {
      handler: function (val) {
        const { length } = val || [];
        this.statusList = Array.from({ length }).map(() => ({
          status: this.import_statuses.PENDING,
          messages: [],
          hover: false,
        }));
        this.$forceUpdate();
      },
      deep: true,
    },
    error: function (err) {
      if (err) {
        this.$refs.g_alert && this.$refs.g_alert.setGraphQlError(err);
      }
    },
    tableFields: {
      handler: function (val) {
        console.log("table-fields", val);
      },
      deep: true,
    },
    userSettings: {
      handler: function (to, fr) {
        console.log("userSettings", { to, fr });
        if (!this.firstSetup) {
          this.doConfigurationSettings(to);
          this.firstSetup = true;
        }
      },
      deep: true,
    },
    configurationSettings: {
      handler: debounce(function () {
        this.updateConfiguration();
      }, 1000),
      deep: true,
    },
  },
  mounted() {
    console.log("tablefields-mounted", this.tableFields);
    console.log("fields-mounted", this.fields);
    this.$store.dispatch("import_settings/getSettings");
  },
  methods: {
    doConfigurationSettings({ columns, row_no, defaults: old_defaults }) {
      const defaultFields = ["honorific", "gender", "date_from", "date_to"];
      console.log({ columns, row_no, old_defaults });
      old_defaults = old_defaults || [];

      const columns_map = columns.reduce((_map, { name, position }) => {
        _map[name] = position;
        return _map;
      }, {});

      row_no = row_no || 1;

      const { fields } = this;
      if (columns.length == 0 && row_no == 1) {
        let i = 0;
        for (const x of fields) {
          columns_map[x.name] = i++;
        }
      }

      const defaults = [];
      for (const name of defaultFields) {
        const { value } = old_defaults.find((x) => x.name == name) || {};
        const { type } = fields.find((x) => x.name == name) || {};
        defaults.push({ name, value, type });
      }

      this.$set(this.configurationSettings, "row_no", row_no);
      this.$set(this.configurationSettings, "columns_map", columns_map);
      this.$set(this.configurationSettings, "defaults", defaults);

      console.log("config settings", this.configurationSettings);
    },

    async updateConfiguration() {
      const { g_alert } = this.$refs && this.$refs;
      try {
        const { columns_map, row_no, defaults } = this.configurationSettings;
        this.formatData(this.csvData);
        await this.$store.dispatch("import_settings/updateSettings", {
          columns_map,
          row_no,
          defaults,
        });
        g_alert && g_alert.setAlert("Settings updated successfully", true);
      } catch (err) {
        g_alert && g_alert.setAlert(err.message, false);
      }
    },

    formatData(csvData) {
      if (!csvData) return;
      const { data, errors, meta } = csvData;
      let { columns_map, row_no } = this.configurationSettings;

      console.log({ data, errors, meta });

      row_no = parseInt(row_no, 10) || 0;
      console.log({ columns_map, row_no });
      const keys = Object.keys(columns_map);
      console.log("keys", keys);

      const getFieldNames = (columnNo) => {
        return keys.filter((key) => columns_map[key] === columnNo);
      };

      this.importList = data.slice(row_no - 1).reduce((_output, columns) => {
        const record = {};
        let valid = false;
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].length) {
            for (let fieldname of getFieldNames(i)) {
              record[fieldname] = columns[i];
              valid = true;
            }
          }
        }
        if (valid) _output.push(record);
        return _output;
      }, []);
    },

    async uploadData() {
      const $this = this;

      const {
        configurationSettings: { defaults },
      } = this;

      for (let i = 0; i < this.importList.length; i++) {
        const setStatus = (status, messages) => {
          $this.$set($this.statusList, i, { status, messages });
          $this.submitting =
            status == this.import_statuses.CHECKING ||
            status == this.import_statuses.PENDING ||
            status == this.import_statuses.SAVING;
        };
        await this.uploadRecord(this.importList[i], defaults, setStatus);
      }
    },

    getParsedData(key, configurations, valueType = "string") {
      try {
        const data = configurations.find((x) => x.key == key);
        switch (valueType) {
          case "array":
          case "object":
            return JSON.parse(data.text_value);
          case "string":
            return data.text_value;
          case "int":
            return parseInt(data.text_value, 10);
        }
      } catch (err) {
        switch (valueType) {
          case "array":
            return [];
          case "object":
            return {};
          case "string":
            return null;
          case "int":
            return null;
        }
      }
    },
    //tables methods
    nextPage() {
      if (this.currentPage + 1 <= this.numberOfPages) this.currentPage += 1;
    },
    formerPage() {
      if (this.currentPage - 1 >= 1) this.currentPage -= 1;
    },
    updateItemsPerPage(number) {
      this.perPage = number;
    },
  },
};
</script>

<style scoped>
/* Helper classes */
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
span.underline {
  border-bottom: 1px solid grey;
  display: inline-block;
}

th.main,
td.main {
  border: 1px solid black;
  margin: 1px;
}

th.status,
td.status {
  border: 1px solid white;
  margin: 1px;
  font-style: italic;
  color: darkolivegreen;
}
</style>
