<template>
  <Fragment>
    <pdf-viewer v-if="isDocumentView" @exit="exitViewer" />
    <main-view v-else />
  </Fragment>
</template>

<script>
import { watch, ref, computed } from "@vue/composition-api";
import { Fragment } from "vue-frag";
import EventBus from "./plugins/event-bus";
import PdfViewer from "./views/PdfViewer.vue";
import MainView from "./layouts/Index.vue";
import store from "./store";
//const src = "/cbarhandbook.pdf";

export default {
  name: "App",

  components: {
    PdfViewer,
    Fragment,
    MainView,
  },

  setup() {
    EventBus.$on("document-viewer", () => {
      console.log("bingo");
      isDocumentView.value = true;
    });
    const isDocumentView = ref(false);

    const exitViewer = () => {
      console.log("exit");
      isDocumentView.value = false;
    };

    ////////////////////computed////////////////
    const authorized = computed(() => store.getters["account/authorized"]);
    console.log("_authorized", authorized.value);

    ////////////////////////////watcher
    watch(authorized, function (_authorized) {
      console.log("_authorized", _authorized);
      if (_authorized) {
        store.dispatch("community/getCommunityMeta");
      } else {
        store.commit("community/SET_COMMUNITY", null);
      }
    });
    ///////////////////////////////

    return {
      isDocumentView,
      exitViewer,
      authorized,
    };
  },
};
</script>