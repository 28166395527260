import Vue from "vue";
import Vuex from "vuex";

import account from "./account.module";
import community from "./community.module";
import member from "./member.module";
import skill from "./skill.module";
import import_settings from "./import_settings.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { account, community, member, skill, import_settings },
  state: {
    community_domain: null,
  },

  mutations: {
    SET_COMMUNITY_NAME(state) {
      const { hostname } = window.location;
      state.community_domain = hostname;
    },
  },
});
