<template>
  <generic-dialog
    @input="exitDisplay"
    @display="viewGroup"
    @delete="deleteSkill"
    @save="saveSkill"
    :items="skills"
    :headers="headers"
    :loading="loading"
    :deleting="deleting"
    :title="title"
    :show-item="showItem"
    :list-label="listLabel"
    :add-new-label="addNewLabel"
    :close-label="closeLabel"
    :save-Label="saveLabel"
    :show-delete-button="showDeleteButton"
  >
    <template #activator="{ attrs, on }">
      <slot :attrs="attrs" :on="on" name="activator" />
    </template>
    <template #display>
      <v-form
        v-if="showItem"
        @submit.prevent.stop="submitDocument()"
        ref="group-model"
        lazy-validation
      >
        <v-card flat height="250px">
          <v-text-field
            v-model="skillModel.name"
            :rules="[(v) => !!v || 'Name is required']"
            placeholder="Name"
            required
            label="Name"
          ></v-text-field>

          <v-text-field
            v-model="skillModel.description"
            label="Description"
          ></v-text-field>
        </v-card>
      </v-form>
    </template>
    <template #default>
      <generic-alert ref="g_alert" />
    </template>
  </generic-dialog>
</template>

<script>
import EventBus from "../plugins/event-bus";
import GenericAlert from "../components/GenericAlert.vue";
import GenericDialog from "../components/GenericDialog.vue";
export default {
  name: "ManageSkills",
  components: { GenericDialog, GenericAlert },

  data: () => ({
    listLabel: "Skills",
    addNewLabel: "Add Skill",
    closeLabel: "Close",
    saveLabel: "Save",
    document: null,
    loading: false,
    deleting: false,
    skillModel: null,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Description",
        value: "description",
      },
      { text: "Edit", value: "actions", sortable: false },
    ],
  }),
  computed: {
    showItem() {
      return !!this.skillModel;
    },
    showDeleteButton() {
      return !!(this.skillModel && this.skillModel.id);
    },
    title() {
      if (!this.skillModel) return "Manage Skills";
      else if (this.skillModel.id) return "Edit Skill";
      else return "Add New Skill";
    },
    skills() {
      return this.$store.state.skill.skills;
    },
    skill() {
      return this.$store.state.skill.skill;
    },
  },
  watch: {},
  created() {
    this.$store.dispatch("skill/listSkills");
  },
  mounted() {
    console.log("skill mounted");
  },

  methods: {
    exitDisplay() {
      if (this.showItem) {
        this.skillModel = null;
      } else {
        EventBus.$emit("generic-dialog-state", false);
      }
    },
    viewGroup(item) {
      console.log("item", item);
      if (item) {
        this.skillModel = { ...item };
      } else {
        this.skillModel = {
          name: "",
          description: "",
        };
      }
    },
    async saveSkill() {
      try {
        this.loading = true;
        if (!this.$refs[`group-model`].validate())
          throw new Error("Invalid submission");

        const params = { ...this.skillModel };
        console.log("params", params);
        const action = `skill/${params.id ? "update" : "add"}Skill`;
        await this.$store.dispatch(action, params);
        this.skillModel = null;
      } catch (err) {
        console.error("saveSkill err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteSkill() {
      try {
        this.loading = true;
        if (!this.$refs[`group-model`].validate())
          throw new Error("Invalid submission");

        const { id } = this.skillModel;
        const action = `skill/deleteSkill`;
        await this.$store.dispatch(action, id);
        this.skillModel = null;
      } catch (err) {
        console.error("saveSkill err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
