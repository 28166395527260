<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <!-- mfaconfirm -->
    <v-card v-if="id === 'mfa-confirmed'">
      <v-card-title>Verify your SMS Code</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="smsConfirm()"
          ref="form-mfa-confirm"
          autocomplete="off"
        >
          <v-text-field
            v-model="smsCode"
            type="text"
            autocomplete="new-password"
            label="SMS Code"
            required
            hint="Check your Phone for the SMS Code."
            persistent-hint
            :rules="confirmationRules"
          ></v-text-field>
          <button ref="mfa-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn @click="$refs['mfa-submit'].click()" :loading="loading"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- login -->
    <v-card v-if="id === 'signin'">
      <v-card-title>Login</v-card-title>

      <v-card-text>
        <v-form
          @submit.prevent.stop="userLogin()"
          ref="form-login"
          autocomplete="off"
        >
          <v-text-field
            v-model="username"
            :rules="usernameAnyRules"
            label="Username"
            required
          ></v-text-field>
          <v-text-field
            v-model="loginPassword"
            type="password"
            label="Password"
            required
            autocomplete="off"
          ></v-text-field>
          <button ref="login-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn @click="$refs['login-submit'].click()" :loading="loading"
          >Login</v-btn
        >
      </v-card-actions>
      <v-card-text>
        <account-notes :items="signinNotes" @trigger="changeAction" />
      </v-card-text>
    </v-card>

    <!-- signup -->
    <v-card v-if="id === 'signup'">
      <v-card-title> Signup</v-card-title> <v-spacer></v-spacer>
      <v-card-text>
        <v-form
          @submit.prevent.stop="userSignup()"
          ref="form-signup"
          autocomplete="off"
        >
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="signupForm.username"
                  label="Username"
                  required
                  :rules="usernameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="signupForm.email"
                  label="Email address"
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="signupForm.password"
                  type="password"
                  label="Password"
                  required
                  autocomplete="new-password"
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="signupForm.passwordConfirm"
                  type="password"
                  label="Confirm Password"
                  required
                  autocomplete="new-password"
                  :rules="[
                    (v) =>
                      v == signupForm.password || 'Does not match password',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <button ref="submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn @click="$refs.submit.click()" :loading="loading">Signup</v-btn>
      </v-card-actions>
      <v-card-text>
        <account-notes :items="signupNotes" @trigger="changeAction" />
      </v-card-text>
    </v-card>

    <!-- confirm signup -->
    <v-card v-if="id === 'signup-confirmed'">
      <v-card-title>Confirm Your Email</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="confirmSignUp()"
          ref="form-signup-confirmed"
          autocomplete="off"
        >
          <v-text-field
            v-model="confirmUsername"
            label="Username"
            required
            autocomplete="off"
            :rules="usernameRules"
          ></v-text-field>
          <v-text-field
            v-model="confirmCode"
            type="text"
            autocomplete="new-password"
            label="Confirmation Number"
            required
            hint="Check your email (and spam folder) for a confirmation Number."
            persistent-hint
            :rules="confirmationRules"
          ></v-text-field>
          <button ref="confirm-signup-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn
          @click="$refs['confirm-signup-submit'].click()"
          :loading="loading"
          >Confirm</v-btn
        >
      </v-card-actions>

      <v-card-text>
        <account-notes :items="confirmSignupNotes" />
      </v-card-text>
    </v-card>

    <!-- forgot password -->
    <v-card v-if="id === 'forgot-password'">
      <v-card-title>Forgot Password</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="sendForgotPasswordCode()"
          ref="form-forgot-password"
          autocomplete="off"
        >
          <v-text-field
            v-model="username"
            :rules="usernameAnyRules"
            label="Username"
            required
          ></v-text-field>
          <button ref="forgot-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)">Cancel</v-btn>
        <v-btn @click="$refs['forgot-submit'].click()" :loading="loading"
          >Login</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- forgot-password confirm -->
    <v-card
      v-if="id === 'forgot-password-confirm'"
      class="mx-auto mt-6"
      width="400"
    >
      <v-card-title>Verify Your Email</v-card-title>

      <v-card-text>
        <v-form
          @submit.prevent.stop="verifyForgotPasswordCode()"
          ref="form-forgot-password-confirm"
          autocomplete="off"
        >
          <v-text-field
            v-model="username"
            label="Username"
            required
            autocomplete="off"
            :rules="usernameAnyRules"
          ></v-text-field>

          <v-text-field
            v-model="confirmCode"
            type="text"
            label="Verification Code"
            required
            hint="Check your email (and spam folder) for a verification code."
            persistent-hint
            :rules="confirmationRules"
            autocomplete="off"
          ></v-text-field>

          <v-text-field
            v-model="newPassword"
            type="password"
            label="New Password"
            required
            autocomplete="off"
            :rules="passwordRules"
          ></v-text-field>
          <button ref="forgot-password-confirm-submit" v-show="false">
            Submit
          </button>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn
          @click="$refs['forgot-password-confirm-submit'].click()"
          :loading="loading"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
    <generic-alert ref="g_alert" />
  </v-dialog>
</template>

<script>
import AccountNotes from "./AccountNotes.vue";
import GenericAlert from "./GenericAlert.vue";

// Dependencies ===============
const { getData } = require("country-list");
// Core =======================
const currentYear = new Date().getFullYear();
//strong password rules
const strongPasswordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
const usernameWithUpperPattern = /^[A-Za-z][A-Za-z0-9_]{3,29}$/;
const usernamePattern = /^[a-z][a-z0-9_]{3,29}$/;
export default {
  components: { GenericAlert, AccountNotes },
  name: "Account",
  props: ["value"],
  data: () => ({
    dialog: false,
    id: "signin",
    signupForm: {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    smsCode: null,
    username: "",
    isLogin: true,
    loginEmail: "",
    loginPassword: "",
    confirmUsername: "",
    confirmCode: "",
    newPassword: "",

    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && strongPasswordPattern.test(v)) ||
        "Password must be between 8 and 30 characters with at least one Uppercase letter, one lowercase letter, one number and one special character e.g. $%&^*@#!()-_",
    ],
    usernameAnyRules: [
      (v) => !!v || "Username is required",
      (v) =>
        (!!v && usernameWithUpperPattern.test(v)) ||
        "Must be between 4 and 30 characters, must start with a letter and include only letters, numbers and/or underscore characters",
    ],
    usernameRules: [
      (v) => !!v || "Username is required",
      (v) =>
        (!!v && usernamePattern.test(v)) ||
        "Must be between 4 and 30 characters, must start with a letter and include only letters, numbers and/or underscore characters. Only Lowercase letters!",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],

    yearRules: [
      (v) => !!v || "Year is required",
      (v) => /^(19|20)\d{2}$/.test(v) || "Year must be valid",
      (v) => v < currentYear - 8 || "You must be older than 12",
    ],
    confirmationRules: [
      (v) => !!v || "Verification code is required",
      (v) => (v && v.length >= 5) || "Invalid Verification code",
    ],
    signupNotes: [
      { key: "signin", text: "Already have an account? Click to Login!" },
      {
        key: "signup-confirmed",
        text: "Already received a confirmation email? Click to Confirm Email!",
      },
    ],
    signinNotes: [
      { key: "forgot-password", text: "Forgot password?" },
      {
        key: "signup",
        text: "Do not have an account? Click to Signup?",
      },
    ],
    confirmSignupNotes: [
      {
        key: null,
        text: "You will need to login after confirming your email.",
      },
    ],
  }),
  computed: {
    countries() {
      const countries = getData();
      countries.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return countries;
    },

    mfaConfirmed: {
      get() {
        return this.$store.getters["account/mfaConfirmed"];
      },
      set() {
        this.$store.commit("account/SET_MFA_USER", null);
      },
    },
    signupConfirm: {
      get() {
        return this.$store.state.account.signupConfirm;
      },
      set(value) {
        this.$store.commit("account/SET_SIGNUP_CONFIRM", value);
      },
    },

    forgotPasswordConfirm: {
      get() {
        return this.$store.state.account.forgotPasswordConfirm;
      },
      set(value) {
        this.$store.commit("account/SET_PASSWORD_CONFIRM", value);
      },
    },
    authorized() {
      return this.$store.getters["account/authorized"];
    },
    user() {
      return this.$store.state.account.user;
    },
    loading() {
      return this.$store.state.account.loading;
    },
  },
  watch: {
    value: function (_type) {
      this.id = _type;
      this.dialog = !!_type;
      this.resetData();
    },

    "$store.state.account.error": function (error) {
      console.log("error", error);
      if (error) this.$refs.g_alert.setGraphQlError(error);
    },

    mfaConfirmed: function (_confirmed) {
      this.id = _confirmed ? "mfa-confirmed" : "signin";
    },
    signupConfirm: function (_confirmed) {
      this.id = _confirmed ? "signup-confirmed" : "signin";
    },
    forgotPasswordConfirm: function (to, from) {
      this.id = from
        ? "signin"
        : to
        ? "forgot-password-confirm"
        : "forgot-password";
    },
  },
  mounted() {
    this.noAutoComplete();
  },
  created() {
    this.signupConfirm =
      this.$store.state.account.user &&
      this.$store.state.account.user.attributes &&
      !this.$store.state.account.user.attributes.email_verified;
    this.mfaConfirmed = false;
    this.forgotPasswordConfirm = false;
  },
  methods: {
    changeAction(evt) {
      console.log("evt", evt);
      this.id = evt;
    },
    resetData() {
      this.signupForm = {
        username: "",
        email: "",
        password: "",
        passwordConfirm: "",
      };
      this.smsCode = null;
      this.username = "";
      this.isLogin = true;
      this.loginEmail = "";
      this.loginPassword = "";
      this.confirmUsername = "";
      this.confirmCode = "";
      this.newPassword = "";
      this.$store.commit("account/SET_LOADING", false);
    },
    smsConfirm() {
      if (!this.$refs["form-mfa-confirm"].validate()) return;
      this.$store.dispatch("account/confirmLogin", this.smsCode);
    },
    userLogin() {
      if (!this.$refs["form-login"].validate()) return;
      this.$store.dispatch("account/login", {
        username: this.username,
        password: this.loginPassword,
      });
    },
    userSignup() {
      console.log("form", this.signupForm);
      if (!this.$refs["form-signup"].validate()) return;
      this.$refs.g_alert.setAlert();
      const { username, email, password } = this.signupForm;
      this.confirmUsername = username;
      this.$store.dispatch("account/signup", {
        username,
        email,
        password,
      });
    },
    confirmSignUp() {
      if (!this.$refs["form-signup-confirmed"].validate()) return;
      this.$store.dispatch("account/confirmSignUp", {
        username: this.confirmUsername,
        code: this.confirmCode,
      });
    },
    sendForgotPasswordCode() {
      if (!this.$refs["form-forgot-password"].validate()) return;
      this.$store.dispatch("account/sendForgotPasswordCode", this.username);
    },
    verifyForgotPasswordCode() {
      if (!this.$refs["form-forgot-password-confirm"].validate()) return;
      this.$store.dispatch("account/verifyForgotPasswordCode", {
        username: this.username,
        code: this.confirmCode,
        new_password: this.newPassword,
      });
    },
    noAutoComplete() {
      this.$el
        .querySelectorAll('input[type="text"][autocomplete="off"')
        .forEach((it) => {
          it.setAttribute("autocomplete", "new-password");
        });
    },
  },
};
</script>
