// Dependencies ===============

/* import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify"; */
import { Auth } from "aws-amplify";

const store = { namespaced: true };

// State ======================

store.state = {
  user: null,
  mfa_user: null,
  error: null,
  signupConfirm: false,
  forgotPasswordConfirm: false,
  action_completed: false,
  identity_token: false,
  loading: false,
};

// Getters ====================

store.getters = {
  userid: (state) => state.user.attributes.sub,
  username: (state) => state.user.username,
  authorized: (state) =>
    !!state.user &&
    state.user.attributes &&
    (state.user.attributes.email_verified ||
      state.user.attributes.phone_number_verified),
  mfaConfirmed: (state) => !!state.mfa_user,
  attributes: (state) => ({ ...state.user, ...state.user.attributes }),
};

// Mutations ==================

store.mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_MFA_USER(state, mfa_user) {
    state.mfa_user = mfa_user;
  },
  SET_SIGNUP_CONFIRM(state, signupConfirm) {
    state.signupConfirm = !!signupConfirm;
  },

  SET_PASSWORD_CONFIRM(state, isconfiremd) {
    state.forgotPasswordConfirm = isconfiremd;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ACTION_COMPLETED(state, action_completed) {
    state.action_completed = action_completed;
  },
  SET_IDENTITY_TOKEN(state, token_state) {
    state.identity_token = token_state;
  },
};

// Actions ====================

store.actions = {
  async confirmLogin({ dispatch, commit, state }, code) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const user = state.mfa_user;
      const mfaType = "SMS_MFA";
      await Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        code, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
    } catch (err) {
      console.log(`Login Error [${err}]`);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser");
  },
  async login({ dispatch, commit }, { username, password }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      commit("SET_MFA_USER", null);
      const user = await Auth.signIn(username, password);
      console.log("user", user);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        commit("SET_MFA_USER", user);
        return;
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        /* const { requiredAttributes } = user.challengeParam;  */
        // the array of required attributes, e.g ['email', 'phone_number']
        // You need to get the new password and required attributes from the UI inputs
        // and then trigger the following function with a button click
        // For example, the email and phone_number are required attributes
        /*  const { username, email, phone_number } = getInfoFromUserInput();
        const loggedUser = await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword, // the new password
          // OPTIONAL, the required attributes
          {
            email,
            phone_number,
          }
        ); */
      } else if (user.challengeName === "MFA_SETUP") {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        /*  Auth.setupTOTP(user); */
      } else {
        // The user directly signs in
        console.log(user);
      }
    } catch (err) {
      console.log(`Login Error [${err}]`);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser");
  },
  async signup({ commit }, { username, email, password }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
        validationData: [],
      });
      //switch email confirmation form
      commit("SET_SIGNUP_CONFIRM", true);
    } catch (err) {
      console.log(`Signup Error [${err}]`);
      commit("SET_ERROR", err.message || err);
      commit("SET_SIGNUP_CONFIRM", false);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async confirmSignUp({ commit }, { username, code }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: true,
      });
      commit("SET_SIGNUP_CONFIRM", false);
    } catch (err) {
      console.log(`Confirm Error [${err}]`);
      commit("SET_ERROR", err.message || err);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async authState({ commit, dispatch }, state) {
    if (state === "signedIn") await dispatch("fetchUser");
    else commit("SET_USER", null);
  },

  async fetchUser({ commit, dispatch }, bypassCache = false) {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache });
      const expires =
        user.getSignInUserSession().getIdToken().payload.exp -
        Math.floor(new Date().getTime() / 1000);
      console.log(`Token expires in ${expires} seconds`);
      setTimeout(async () => {
        console.log("Renewing Token");
        await dispatch("fetchUser");
      }, expires * 1000);
      commit("SET_USER", user);
    } catch (err) {
      // console.error("fetchuser error", err);
      // commit("SET_USER", null);
      await dispatch("logout");
    }
  },
  async logout({ commit }) {
    await Auth.signOut();
    commit("SET_USER", null);
  },

  // Send confirmation code to user's email
  async sendForgotPasswordCode({ commit }, username) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      console.log(username);
      const data = await Auth.forgotPassword(username);
      console.log("sendForgotPasswordCode results", data);
      commit("SET_PASSWORD_CONFIRM", true);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async verifyForgotPasswordCode({ commit }, { username, code, new_password }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const data = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      console.log(data);
      commit("SET_PASSWORD_CONFIRM", false);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async changePassword({ dispatch, commit }, { old_password, new_password }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      commit("SET_ACTION_COMPLETED", false);
      const user = await Auth.currentAuthenticatedUser();
      let result = await Auth.changePassword(user, old_password, new_password);
      console.log("changePassword", result);
      commit("SET_ACTION_COMPLETED", true);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser", true);
  },

  async updateUserAttributes(
    { dispatch, commit },
    { attributes, password, passwordRequired }
  ) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      commit("SET_ACTION_COMPLETED", false);
      const user = await Auth.currentAuthenticatedUser();
      if (passwordRequired) {
        await Auth.changePassword(user, password, password);
      }
      await Auth.updateUserAttributes(user, attributes);
      commit("SET_ACTION_COMPLETED", true);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser", true);
  },

  async verifyAttribute({ dispatch, commit }, { attribute, password }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      commit("SET_IDENTITY_TOKEN", false);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, password, password);
      await Auth.verifyCurrentUserAttribute(attribute);
      commit("SET_IDENTITY_TOKEN", true);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser", true);
  },

  async confirmAttributeToken({ dispatch, commit }, { attribute, token }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      commit("SET_ACTION_COMPLETED", false);
      //attribute eother email or phone_number
      await Auth.verifyCurrentUserAttributeSubmit(attribute, token);
      commit("SET_ACTION_COMPLETED", true);
    } catch (err) {
      console.log(err);
      commit("SET_ERROR", err.message || err);
      return;
    } finally {
      commit("SET_LOADING", false);
    }
    dispatch("fetchUser", true);
  },
};

// Export =====================
export default store;
