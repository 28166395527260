<template>
  <div>
    <v-file-input
      @change="loadFile"
      accept="text/csv"
      label="File input"
    ></v-file-input>
  </div>
</template>

<script>
import Papa from "papaparse";
export default {
  methods: {
    loadFile(file) {
      ((r, $) => {
        r.onload = (e) => $.$emit("get-csv", Papa.parse(e.target.result));
        r.readAsText(file);
      })(new FileReader(), this);
    },
  },
};
</script>