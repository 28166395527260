<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          {{ content }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" text @click="confirmDialog"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    content: {
      default: "Press OK to Confirm!",
      type: String,
    },
    title: {
      default: "Confirm",
      type: String,
    },
    status: {
      default: "",
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    value: function (val) {
      this.dialog = val;
    },
    dialog: function (val) {
      this.$emit("input", val);
    },
  },

  methods: {
    closeDialog() {
      this.$emit("input", false);
    },

    confirmDialog() {
      this.$emit("ok", this.status);
    },
  },
};
</script>