<template>
  <v-row no-gutters>
    <v-col>
      <v-btn
        :disabled="currentNum <= 1"
        @click="$emit('set-first-page')"
        block
        icon
        x-large
      >
        <v-icon> mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
    </v-col>

    <v-col>
      <v-btn
        :disabled="currentNum <= 1"
        @click="$emit('set-prev-page')"
        block
        icon
        x-large
      >
        <v-icon> mdi-arrow-left-thin-circle-outline</v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <v-btn block plain class="mt-3">{{ currentNum }}/{{ pageNum }}</v-btn>
    </v-col>
    <v-col>
      <v-btn
        :disabled="currentNum >= pageNum"
        @click="$emit('set-next-page')"
        block
        icon
        x-large
      >
        <v-icon> mdi-arrow-right-thin-circle-outline</v-icon>
      </v-btn>
    </v-col>
    <v-col>
      <v-btn
        :disabled="currentNum >= pageNum"
        @click="$emit('set-last-page')"
        block
        icon
        x-large
      >
        <v-icon>mdi-arrow-right-bold-circle</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "FooterPageNavigator",
  props: ["currentNum", "pageNum"],
};
</script>