<template>
  <cafe-pdf ref="pdf" @on-success="load" @on-scroll="scroll" :filePath="src">
    <template slot="header">
      <div data-app>
        <v-toolbar width="100%">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="documentListMenu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-folder-multiple</v-icon>
              </v-btn>
            </template>

            <v-list-item v-if="documentItems.length == 0">
              <v-list-item-title>No Documents Loaded!</v-list-item-title>
            </v-list-item>

            <v-list>
              <v-list-group
                v-for="(group, index) in documentItems"
                :key="`docments-${index}`"
                v-model="dtoggleList[index]"
                :append-icon="
                  dtoggleList[index] ? 'mdi-folder-open' : 'mdi-folder'
                "
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ group.name }}</v-list-item-title>
                </template>

                <v-list-item
                  v-for="(item, i) in group.children"
                  :key="i"
                  @click.stop="item.isFile && setDocument(item)"
                >
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="blue darken-2">mdi-bookmark</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                v-for="link in selectedLinks"
                :key="link.title"
                @click="pageChange(link.page_no)"
              >
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="selectedLinks.length == 0">
                <v-list-item-title>No Bookmark Defined!</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-crop-free</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in scales"
                @click="scale = item.value"
                :key="index"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn icon @click.stop="$emit('exit')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
    </template>
    <template slot="footer">
      <div data-app>
        <footer-page-navigator
          :current-num="currentNum"
          :page-num="pageNum"
          @set-prev-page="setPrevPage"
          @set-next-page="setNextPage"
          @set-first-page="pageChange(1)"
          @set-last-page="pageChange(pageNum)"
        />
      </div>
    </template>
  </cafe-pdf>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import store from "../store";
import { capitalCase } from "change-case";
import cafePdf from "cafe-pdf";
import FooterPageNavigator from "../components/FooterPageNavigator.vue";
import "cafe-pdf/package/cafe-pdf.css";
const _src =
  "data:application/pdf;base64,JVBERi0xLjUKJYCBgoMKMSAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvRmlyc3QgMTQxL04gMjAvTGVuZ3==";

export default {
  name: "app",
  components: { cafePdf, FooterPageNavigator },
  setup() {
    const pdf = ref(null);
    const pageNum = ref(null);
    const scale = ref("auto");
    const currentNum = ref(1);

    const src = ref(_src);
    const title = ref("Document Viewer");

    const loading = ref(false);
    ///reactive objects
    const selectedLinks = ref([]);
    const dtoggleList = ref([]);
    const documentListMenu = ref(false);

    const scales = computed(() => [
      { value: "auto", text: "auto" },
      { value: "page-actual", text: "actual" },
      { value: "page-fit", text: "fit" },
      { value: "page-width", text: "width" },
      { value: "0.50", text: "50%" },
      { value: "1", text: "100%" },
      { value: "2", text: "200%" },
      { value: "4", text: "400%" },
    ]);

    const document_metas = computed(() => {
      return store.getters["community/document_metas"];
    });

    const documentItems = computed(() => {
      const items = document_metas.value.reduce((_treeArr, _item, index) => {
        let id = _treeArr.findIndex((x) => x.name === _item.group.name);
        let node = null;
        if (id >= 0) {
          node = _treeArr[id];
        } else {
          id = _treeArr.length;
          const name = _item.group.name;
          const children = [];
          node = { id, name, children, isFile: false };
        }
        const childNode = {
          id: node.children.length,
          name: _item.title,
          key: _item.key,
          isFile: true,
          index,
        };
        node.children.push(childNode);
        _treeArr.splice(id, 1, node);
        return _treeArr;
      }, []);
      return items;
    });

    const hashedFile = computed(() => {
      return store.getters["community/hashedFile"];
    });

    const file_data = computed(() => {
      return store.state.community.file_data;
    });

    ////watch
    watch(documentItems, (items) => {
      dtoggleList.value = new Array(items.length).map(() => true);
    });

    watch(scale, (val) => {
      setScale(val);
    });

    watch(hashedFile, () => {
      loading.value = false;
      const prefix = "data:application/pdf;base64,";
      src.value = file_data.value ? prefix + file_data.value : _src;
    });

    //methods
    const load = (val) => {
      pageNum.value = val;
    };

    const setScale = (val) => {
      pdf.value.changeScale(val);
    };

    const scroll = (val) => {
      currentNum.value = val;
    };

    const setPrevPage = () => {
      pdf.value.prePage();
    };

    const setNextPage = () => {
      pdf.value.nextPage();
    };
    const pageChange = (val) => {
      pdf.value.goToPage(val);
    };
    const print = () => {
      pdf.value.printFile();
    };

    const setDocument = async ({ name, key, index, links }) => {
      try {
        console.log("links", links);
        loading.value = true;
        await store.dispatch("community/getBase64Document", key);
        console.log("document", document_metas.value[index]);
        selectedLinks.value = document_metas.value[index].links;

        documentListMenu.value = false;
        title.value = capitalCase(name);
      } catch (err) {
        console.log("setDocument err", err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      console.log("pdf:", pdf.value.value);
      setScale(scale.value);
    });

    return {
      /////computed//////////
      scales,
      documentItems,

      ///data////
      pdf,
      pageNum,
      scale,
      currentNum,
      selectedLinks,
      src,
      title,
      dtoggleList,
      documentListMenu,
      //////////methods////////
      load,
      setPrevPage,
      setNextPage,
      setDocument,
      setScale,
      scroll,
      pageChange,
      print,
    };
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
#app {
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
@media print {
  #app {
    display: none;
  }
}
</style>