import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
//import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const store = { namespaced: true };

const toLowerCase = (a) => (a || "").toLowerCase();

const compareMembers = (a, b) =>
  toLowerCase(a.first_name) < toLowerCase(b.first_name)
    ? -1
    : toLowerCase(a.first_name) > toLowerCase(b.first_name)
    ? 1
    : toLowerCase(a.last_name) < toLowerCase(b.last_name)
    ? -1
    : toLowerCase(a.last_name) > toLowerCase(b.last_name)
    ? 1
    : toLowerCase(a.nick_name) < toLowerCase(b.nick_name)
    ? -1
    : toLowerCase(a.nick_name) > toLowerCase(b.nick_name)
    ? 1
    : toLowerCase(a.username) < toLowerCase(b.username)
    ? -1
    : toLowerCase(a.username) > toLowerCase(b.username)
    ? 1
    : 0;

// State ======================
store.state = {
  member: null,
  members: [],
  loading: false,
  error: null,
};

// Getters ====================
store.getters = {};

// Mutations ==================
store.mutations = {
  SET_MEMBER(state, member) {
    state.member = member;
  },
  SET_MEMBERS(state, members) {
    state.members = members;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

// Actions ====================
store.actions = {
  //get communityMeta
  async getMember({ commit, rootGetters }, member_id) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;
      const {
        data: { getMember: member },
      } = await API.graphql(
        graphqlOperation(queries.getMember, {
          community_id,
          member_id,
        })
      );
      commit("SET_MEMBER", member);
    } catch (err) {
      console.error("getMember error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async referMember({ commit, state, rootGetters }, input) {
    const isImport = !!input.isImport;
    delete input.isImport;
    try {
      if (!isImport) {
        commit("SET_ERROR", null);
        commit("SET_LOADING", true);
      }
      const community_id = rootGetters["community/community"]?.id;
      input.community_id = community_id;
      const {
        data: { referMember: member },
      } = await API.graphql(
        graphqlOperation(mutations.referMember, {
          input,
        })
      );

      const { members } = state;
      members.push(member);
      members.sort(compareMembers);
      commit("SET_MEMBERS", members);

      if (!isImport) commit("SET_MEMBER", member);
    } catch (err) {
      console.error("referMember error:", err);
      if (isImport) throw err;
      else commit("SET_ERROR", err);
    } finally {
      if (!isImport) commit("SET_LOADING", false);
    }
  },
  async updateMember({ commit, state, rootGetters }, input) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;
      input.community_id = community_id;
      const {
        data: { updateMember: member },
      } = await API.graphql(
        graphqlOperation(mutations.updateMember, {
          input,
        })
      );
      const { members } = state;
      const idx = members.findIndex((x) => x.id === member.id);
      members.splice(idx, 1, member);
      commit("SET_MEMBERS", members);
      commit("SET_MEMBER", null);
    } catch (err) {
      console.error("updateMember error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async changeMemberStatus({ commit, state, rootGetters }, { id, newStatus }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;

      const {
        data: { changeMemberStatus: member },
      } = await API.graphql(
        graphqlOperation(mutations.changeMemberStatus, {
          id,
          newStatus,
          community_id,
        })
      );
      const { members } = state;
      const idx = members.findIndex((x) => x.id === id);
      members.splice(idx, 1, member);
      commit("SET_MEMBERS", members);
      commit("SET_MEMBER", null);
    } catch (err) {
      console.error("changeMemberStatus error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async listMembers(
    { commit, rootGetters },
    { search, groups, statuses, skills }
  ) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;
      groups = groups || [];
      statuses = statuses || [];
      skills = skills || [];
      search = search || "";
      const {
        data: { listMembers: members },
      } = await API.graphql(
        graphqlOperation(queries.listMembers, {
          community_id,
          search,
          groups,
          skills,
          statuses,
        })
      );
      members.sort(compareMembers);
      console.log("members", members);
      commit("SET_MEMBERS", members);
    } catch (err) {
      console.error("listMembers error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

// Export =====================
export default store;
