<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="item in fields.filter((x) => x.visible)"
          :key="item.key"
        >
          <v-select
            v-if="item.type == 'multiple'"
            v-model="dataForm[item.key]"
            :items="getOptions(item.key)"
            :label="item.label"
            :disabled="item.disabled"
            multiple
          ></v-select>
          <v-select
            v-else-if="item.type == 'select'"
            v-model="dataForm[item.key]"
            :items="getOptions(item.key)"
            :rules="getRules(item.key)"
            :label="item.label"
            :disabled="item.disabled"
          ></v-select>
          <v-checkbox
            v-else-if="item.type == 'check'"
            v-model="dataForm[item.key]"
            :rules="getRules(item.key)"
            :label="item.label"
            :disabled="item.disabled"
          ></v-checkbox>
          <v-text-field
            v-else
            :type="item.type"
            v-model="dataForm[item.key]"
            :rules="getRules(item.key)"
            :label="item.label"
            :required="item.required"
            :disabled="item.disabled"
          ></v-text-field>
        </v-col>
      </v-row>

      <portal to="form-builder-control">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="validate"
              :loading="loading"
              :disabled="disabled"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="green">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Save Membership Data</span>
        </v-tooltip>
      </portal>
      <!-- <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>

          <v-btn color="warning" @click="resetValidation">
            Reset
          </v-btn> -->
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: "FormControl",
  props: ["item", "fields", "getOptions", "getRules", "loading"],

  data: () => ({
    valid: false,
    isChanged: false,
    dataForm: {},
  }),

  computed: {
    disabled() {
      return !(this.valid && this.isChanged);
    },
  },

  watch: {
    dataForm: {
      handler: function (to, from) {
        console.log("dataForm", { to, from });
        if (Object.keys(from).length) {
          console.log("dataForm changed");
          this.isChanged = true;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.dataForm = this.fields.reduce((obj, item) => {
      obj[item.key] = this.item[item.key] || null;
      return obj;
    }, {});
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        console.log("data", this.dataForm);
        this.$emit("submit", this.dataForm);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>