<template>
  <v-list dense class="mt-n4">
    <v-subheader>Notes</v-subheader>

    <v-list-item
      link
      v-for="(item, index) in items"
      :key="`item-${index}`"
      @click="$emit('trigger', item.key)"
      class="mt-n4"
    >
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ item.text }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "AccountNotes",
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>