 <template>
  <generic-dialog
    @input="exitDisplay"
    @display="viewDocumentMeta"
    @delete="deleteDocument"
    @save="submitDocument"
    :items="documentMetas"
    :headers="headers"
    :loading="loading"
    :deleting="deleting"
    :title="title"
    :show-item="showItem"
    :list-label="listLabel"
    :add-new-label="addNewLabel"
    :close-label="closeLabel"
    :save-Label="saveLabel"
    :show-delete-button="showDeleteButton"
  >
    <template #activator="{ attrs, on }">
      <slot :attrs="attrs" :on="on" name="activator" />
    </template>
    <template #default>
      <generic-alert ref="g_alert" />
    </template>

    <template #display>
      <v-form
        v-if="showItem"
        @submit.prevent.stop="submitDocument()"
        ref="form-document"
        lazy-validation
      >
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#summary"> Summary </v-tab>
          <v-tab href="#bookmarks"> Bookmarks </v-tab>
        </v-tabs>
        <v-container>
          <v-tabs-items v-model="tab">
            <v-tab-item value="summary">
              <v-card flat height="250px" class="mb-6">
                <v-text-field
                  v-model="documentModel.title"
                  :rules="[(v) => !!v || 'Document Name is required']"
                  placeholder="Document Name"
                  required
                  label="Document Name"
                ></v-text-field>
                <v-select
                  :disabled="!!documentModel.key"
                  v-model="documentModel.group.id"
                  :items="groups"
                  label="Group"
                  item-value="group_id"
                  item-text="group_name"
                  required
                  :rules="[(v) => !!v || 'Group is required']"
                ></v-select>
                <v-text-field
                  v-if="!!documentModel.key"
                  disabled
                  v-model="documentModel.file_name"
                  label="File Name"
                ></v-text-field>
                <v-file-input
                  v-else
                  v-model="documentModel.document"
                  placeholder="Upload PDF Document"
                  label="Upload PDF Document"
                  required
                  prepend-icon="mdi-paperclip"
                  accept=".pdf"
                  :rules="[(v) => !!v || 'Document is required']"
                  truncate-length="15"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-card>
            </v-tab-item>

            <v-tab-item value="bookmarks">
              <v-card flat height="250px" class="mb-6">
                <v-row>
                  <v-col cols="5">Name</v-col>
                  <v-col cols="5">Page No</v-col>
                  <v-col cols="2">
                    <v-btn icon block @click="documentModel.links.push({})"
                      ><v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(link, idx) in documentModel.links"
                  :key="`link-${idx}`"
                >
                  <v-col cols="5">
                    <v-text-field
                      v-model="documentModel.links[idx].title"
                      :rules="[(v) => !!v || 'Name is required']"
                      placeholder="Name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="documentModel.links[idx].page_no"
                      :rules="[
                        (v) => (v > 0 && v < 1000) || 'Page No is required',
                      ]"
                      placeholder="Page No"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      icon
                      block
                      @click="documentModel.links.splice(idx, 1)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-form>
    </template>
  </generic-dialog>
</template>

<script>
import EventBus from "../plugins/event-bus";
import GenericAlert from "../components/GenericAlert.vue";
import GenericDialog from "../components/GenericDialog.vue";
export default {
  name: "ManageDocuments",
  components: { GenericDialog, GenericAlert },
  props: ["groups", "documentMetas"],
  data: () => ({
    title: "Manage Documents",
    listLabel: "Documents",
    addNewLabel: "Add Document",
    closeLabel: "Close",
    saveLabel: "Save",
    tab: null,
    document: null,
    loading: false,
    deleting: false,
    documentModel: null,
    headers: [
      { text: "Title", value: "title", align: "start" },
      {
        text: "Group",
        value: "group.name",
      },
     /*  { text: "File", value: "file_name" }, */
      { text: "Edit", value: "actions", sortable: false },
    ],
  }),
  computed: {
    showItem() {
      return !!this.documentModel;
    },
    showDeleteButton() {
      return !!(this.documentModel && this.documentModel.key);
    },
  },
  watch: {},
  mounted() {},
  async created() {},
  methods: {
    exitDisplay() {
      if (this.showItem) {
        this.documentModel = null;
      } else {
        EventBus.$emit("generic-dialog-state", false);
      }
    },
    viewDocumentMeta(item) {
      console.log("item", item);
      if (item) this.documentModel = { ...item };
      else {
        this.documentModel = {
          group: { id: null, name: "" },
          title: null,
          file_name: null,
          document: null,
          links: [],
        };
      }
    },

    submitDocument() {
      if (!this.$refs[`form-document`].validate()) return;
      if (this.documentModel.key) {
        this.modifyDocumentMeta();
      } else {
        this.uploadDocument();
      }
    },
    createBase64File(file) {
      return new Promise((resolve) => {
        // prepare HTML5 FileReader
        const oReader = new FileReader();
        //setLoadingState(true);
        oReader.onload = (e) => {
          resolve(e.target.result);
        };
        // read selected file as DataURL
        oReader.readAsDataURL(file);
      });
    },
    async uploadDocument() {
      try {
        this.loading = true;
        const { type: content_type, name: file_name } =
          this.documentModel.document;
        const file_data = await this.createBase64File(
          this.documentModel.document
        );

        const {
          links,
          group: { id: group_id },
          title,
        } = this.documentModel;

        console.log("model base64", {
          links,
          group_id,
          title,
          file_name,
          content_type,
        });
        const params = {
          links,
          group_id,
          title,
          file_data,
          file_name,
          content_type,
        };
        await this.$store.dispatch("community/uploadPDF", params);
        this.documentModel = null;
      } catch (err) {
        console.error("uploadDocument err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteDocument() {
      try {
        this.deleting = true;

        const {
          group: { id: group_id },

          title,
          key,
        } = this.documentModel;
        const params = {
          group_id,

          key,
          title,
        };

        console.log("key", key);
        await this.$store.dispatch("community/deleteDocument", params);
        this.documentModel = null;
      } catch (err) {
        console.error("deleteDocument err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.deleting = false;
      }
    },
    async modifyDocumentMeta() {
      try {
        this.loading = true;
        const {
          group: { id: group_id },
          links,
          title,
          key,
        } = this.documentModel;
        const params = {
          group_id,
          links,
          key,
          title,
        };
        console.log("modifyDocumentMeta params", params);
        await this.$store.dispatch("community/modifyDocumentMeta", params);
        this.documentModel = null;
      } catch (err) {
        console.error("modifyDocumentMeta err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
