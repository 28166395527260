/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCommunityUpdate = /* GraphQL */ `
  subscription OnCommunityUpdate($id: String!) {
    onCommunityUpdate(id: $id) {
      access_rules {
        email_regex
        group_id
        group_name
        is_member
      }
      admin_list {
        birth_date
        date_from
        date_to
        emails
        first_name
        gender
        groups {
          id
          is_admin
          name
        }
        honorific
        id
        last_name
        middle_name
        nick_name
        phones
        profession {
          description
          id
          name
        }
        referred_by
        skills {
          description
          id
          name
        }
        status
        username
      }
      document_metas {
        file_name
        group {
          id
          is_admin
          name
        }
        key
        links {
          page_no
          title
        }
        title
      }
      selected_community {
        id
        is_admin
        name
      }
      skills {
        description
        id
        name
      }
      user {
        email
        username
      }
      user_communities {
        id
        is_admin
        name
      }
    }
  }
`;
