<template>
  <v-container>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Spreadsheet Row </v-expansion-panel-header>
        <v-expansion-panel-content>
          <portal to="row-spinner" :disabled="isViewable">
            <hexa-spinner
              label="Start Row No"
              :withLabel="isViewable"
              :is-hex="false"
              :is-mandatory="false"
              :min="1"
              :max="100"
              v-model="dataSettings.row_no"
            />
          </portal>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Spreadsheet Columns
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <portal
            v-for="(item, i) in dataFields"
            :key="'sss' + i"
            :to="`col-spinner-${i}`"
            :disabled="isViewable"
          >
            <hexa-spinner
              :label="item.name"
              :withLabel="isViewable"
              :is-hex="true"
              :is-mandatory="item.required"
              :min="-1"
              :max="25"
              v-model="dataSettings.columns_map[item.name]"
            />
          </portal>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Default Columns</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            no-gutters
            v-for="(_, idx) in dataSettings.defaults"
            :key="idx + 'defaults'"
          >
            <v-select
              v-if="dataSettings.defaults[idx].type == 'select'"
              v-model="dataSettings.defaults[idx].value"
              :items="getOptions(dataSettings.defaults[idx].name)"
              :label="dataSettings.defaults[idx].name | toCaptilize"
              required
            ></v-select>
            <v-text-field
              v-else
              :type="dataSettings.defaults[idx].type"
              v-model="dataSettings.defaults[idx].value"
              :label="dataSettings.defaults[idx].name | toCaptilize"
              required
            ></v-text-field>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <portal to="import-settings-actions">
      <v-btn depressed color="primary" @click="emitSettings(dataSettings)"
        >Update</v-btn
      >
    </portal> -->
  </v-container>
</template>
<script>
import { debounce } from "debounce";
import { capitalCase } from "change-case";
import HexaSpinner from "./HexaSpinner.vue";
//import { debounce } from "debounce";
export default {
  components: { HexaSpinner },
  name: "UploadDataSettings",
  props: ["configurations", "dataFields", "getOptions", "isViewable"],
  model: {
    prop: "configurations",
    event: "update-configurations",
  },
  data() {
    return {
      dataSettings: { row_no: 1, columns_map: {}, defaults: [] },
    };
  },
  filters: {
    toCaptilize(val) {
      return capitalCase(val);
    },
  },

  watch: {
    configurations: {
      handler: function (val) {
        this.dataSettings = val;
      },
      deep: true,
    },
    dataSettings: {
      handler: debounce(function (to, from) {
        console.log("dataSettings:", { to, from });
      }, 500),
      deep: true,
    },
  },
  mounted() {
    console.log("mounted configurations", this.configurations);
    this.dataSettings = this.configurations;
  },
  methods: {
    emitSettings(dataSettings) {
      const { columns_map, row_no, defaults: list } = dataSettings;

      console.log("dataSettings-list:", list);
      const defaults = list.map(({ name, value }) => ({
        name,
        value,
      }));
      const values = { columns_map, row_no, defaults };
      console.log("dataSettings-values:", values);
      this.$emit("update-configurations", values);
    },
  },
};
</script>