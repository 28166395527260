<template>
  <v-navigation-drawer v-model="drawer" absolute temporary app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> Menu </v-list-item-title>
        <v-list-item-subtitle></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-expansion-panels v-if="authorized">
      <v-expansion-panel>
        <v-expansion-panel-header>Community</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="2" v-bind="attrs" v-on="on" :loading="loading">
                {{ communityTitle }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                @click="switchCommunity(item.id)"
                v-for="(item, index) in user_communities"
                :key="index"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Admin</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <manage-communities>
              <template #activator="{ attrs, on }">
                <v-list-item link v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>Manage Communities</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </manage-communities>

            <template v-if="is_admin">
              <manage-admin :admin-list="admin_list">
                <template #activator="{ attrs, on }">
                  <v-list-item link v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title
                        >Manage Administrators</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </manage-admin>

              <manage-skills>
                <template #activator="{ attrs, on }">
                  <v-list-item link v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>Manage Skills</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </manage-skills>

              <manage-groups :access-rules="access_rules">
                <template #activator="{ attrs, on }">
                  <v-list-item link v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>Manage Groups</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </manage-groups>

              <manage-documents
                :document-metas="document_metas"
                :groups="access_rules || []"
              >
                <template #activator="{ attrs, on }">
                  <v-list-item link v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>Manage Documents</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </manage-documents>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Applications</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item link @click="viewDocuments">
              <v-list-item-content>
                <v-list-item-title>Document Viewer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="app in apps"
              :key="app.type"
              link
              @click="gotoApp(app.type)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ app.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-center">
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="primary" dark class="mx-auto">
          <v-card-text>Loading Document </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ManageAdmin from "../admin/ManageGroupAdministrators.vue";
import ManageDocuments from "../admin/ManageDocuments.vue";
import ManageGroups from "../admin/ManageGroups.vue";
import ManageCommunities from "../admin/ManageCommunities.vue";
import EventBus from "../plugins/event-bus";
import ManageSkills from "../admin/ManageSkills.vue";

export default {
  name: "SideBar",
  components: {
    ManageAdmin,
    ManageDocuments,
    ManageGroups,
    ManageCommunities,
    ManageSkills,
  },
  data() {
    return {
      loading: false,

      documentSelections: null,

      drawer: false,
      showLink: false,

      apps: [
        /*  { name: "Default", type: "def" }, */
        { name: "Membership", type: "mem" },
      ],
    };
  },

  computed: {
    authorized() {
      return this.$store.getters["account/authorized"];
    },

    admin_list() {
      return this.$store.getters["community/admin_list"];
    },
    access_rules() {
      return this.$store.getters["community/access_rules"];
    },
    document_metas() {
      return this.$store.getters["community/document_metas"];
    },

    is_admin() {
      return this.$store.getters["community/is_admin"];
    },
    communityTitle() {
      return this.$store.getters["community/communityTitle"];
    },
    community() {
      return this.$store.getters["community/community"];
    },
    user_communities() {
      const list = this.$store.getters["community/user_communities"];
      list.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      return list;
    },
  },
  watch: {
    authorized: function (_authorized) {
      console.log("_authorized", _authorized);
      this.gotoApp();
    },

    documentSelections: function (val) {
      console.log("documentSelections---", val);
    },
    "community.id": function (val) {
      console.log("community object", val);
      if (val) {
        this.$store.dispatch("community/onCommunityUpdate", val);
      }
    },

    drawer: function (val) {
      if (val) {
        this.showLink = true;
      }
    },

    loading(val) {
      if (!val) return;
      setTimeout(() => (this.loading = false), 4000);
    },
  },
  created() {
    this.$store.commit("SET_COMMUNITY_NAME");
    this.$store.dispatch("account/fetchUser");
    console.log("domain_name", this.$store.state.domain_name);
    console.log("community object", this.community);
  },
  mounted() {
    EventBus.$on("toggle-drawer", () => {
      this.toggleDrawer();
    });
  },

  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    viewDocuments() {
      EventBus.$emit("document-viewer");
    },
    gotoApp(type) {
      EventBus.$emit("app-selector", type);
    },
    async switchCommunity(id) {
      console.log("community-id", id);
      console.log("community-item", this.community);
      try {
        if (id == this.community.id) return;
        this.loading = true;
        await this.$store.dispatch("community/getCommunityMeta", id);
      } catch (err) {
        console.error("switchCommunity err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>