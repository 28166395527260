import Vue from "vue";
import PortalVue from "portal-vue";
import VueCompositionAPI from '@vue/composition-api'

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";



import "./registerServiceWorker";

import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import AmplifySettings from "./aws-exports.js";

Vue.use(PortalVue);
Vue.config.productionTip = false;

Amplify.configure(AmplifySettings);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueCompositionAPI)

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
