/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBase64Document = /* GraphQL */ `
  query GetBase64Document($community_id: String!, $key: String!) {
    getBase64Document(community_id: $community_id, key: $key)
  }
`;
export const getCommunityMeta = /* GraphQL */ `
  query GetCommunityMeta($input: CommunityInput) {
    getCommunityMeta(input: $input) {
      access_rules {
        email_regex
        group_id
        group_name
        is_member
      }
      admin_list {
        birth_date
        date_from
        date_to
        emails
        first_name
        gender
        groups {
          id
          is_admin
          name
        }
        honorific
        id
        last_name
        middle_name
        nick_name
        phones
        profession {
          description
          id
          name
        }
        referred_by
        skills {
          description
          id
          name
        }
        status
        username
      }
      document_metas {
        file_name
        group {
          id
          is_admin
          name
        }
        key
        links {
          page_no
          title
        }
        title
      }
      selected_community {
        id
        is_admin
        name
      }
      skills {
        description
        id
        name
      }
      user {
        email
        username
      }
      user_communities {
        id
        is_admin
        name
      }
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($community_id: String!, $member_id: String!) {
    getMember(community_id: $community_id, member_id: $member_id) {
      birth_date
      date_from
      date_to
      emails
      first_name
      gender
      groups {
        id
        is_admin
        name
      }
      honorific
      id
      last_name
      middle_name
      nick_name
      phones
      profession {
        description
        id
        name
      }
      referred_by
      skills {
        description
        id
        name
      }
      status
      username
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($community_id: String!) {
    getSettings(community_id: $community_id) {
      columns {
        name
        position
      }
      community_id
      defaults {
        name
        value
      }
      row_no
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($community_id: String!, $id: ID!) {
    getSkill(community_id: $community_id, id: $id) {
      description
      id
      name
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $community_id: String!
    $groups: [String]
    $search: String
    $skills: [String]
    $statuses: [MemberStatus]
  ) {
    listMembers(
      community_id: $community_id
      groups: $groups
      search: $search
      skills: $skills
      statuses: $statuses
    ) {
      birth_date
      date_from
      date_to
      emails
      first_name
      gender
      groups {
        id
        is_admin
        name
      }
      honorific
      id
      last_name
      middle_name
      nick_name
      phones
      profession {
        description
        id
        name
      }
      referred_by
      skills {
        description
        id
        name
      }
      status
      username
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills($community_id: String!, $search: String) {
    listSkills(community_id: $community_id, search: $search) {
      description
      id
      name
    }
  }
`;
