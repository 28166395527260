<template>
  <Fragment>
    <confirm-action
      :content="confirmText"
      :title="confirmTitle"
      :status="newMemberStatus"
      :loading="loading"
      v-model="confirmDialog"
      @ok="changeMemberStatus"
    />

    <import-data
      subject="Import"
      v-model="isImportMembersVisible"
      @cancel="isImportMembersVisible = false"
      :fields="import_fields"
      :get-options="getOptions"
      :upload-record="uploadProspectiveMembers"
    />
    <filter-members
      :all-statuses="statuses"
      :all-groups="groups"
      :all-skills="skills"
      v-model="isFilterDialogVisible"
      :filters.sync="filters"
    />

    <portal to="application-control">
      <v-toolbar>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer v-if="isList"> </v-spacer>

        <v-text-field
          v-if="isList"
          v-model="search"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          type="text"
          clearable
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-toolbar-items v-if="isList">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="isFilterDialogVisible = true"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span class="text-overline"> Filter Members </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="openNewForm"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span class="text-overline"> Refer Member </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="isImportMembersVisible = true"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-upload-multiple</v-icon>
              </v-btn>
            </template>
            <span class="text-overline">Import Prospective Members </span>
          </v-tooltip>
        </v-toolbar-items>

        <v-toolbar-items v-if="!isList">
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="isView = !isView"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ `mdi-${isView ? "pencil" : "eye"}` }}</v-icon>
              </v-btn>
            </template>
            <span class="text-overline">{{
              `${isView ? "View" : "Edit"} Mode`
            }}</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-for="status in validStatuses"
            :key="'status:' + status"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="setConfirmStatus(status)"
                :loading="loading"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ status | filterStatusIcon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ status | filterComments }}</span>
          </v-tooltip>
        </v-toolbar-items>

        <v-spacer v-if="!isList"></v-spacer>
        <portal-target name="form-builder-control"> </portal-target>

        <v-spacer v-if="!isList"></v-spacer>
        <v-btn v-if="!isList" icon @click="membershipForm = null"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
    </portal>

    <v-card v-if="isList">
      <v-data-table
        v-model="selectedMembers"
        :headers="headers"
        :items="members"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        @click:row="selectMember"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ index + 1 }}
        </template>
      </v-data-table>
    </v-card>

    <v-card v-else>
      <view-builder
        v-if="isView"
        :fields="fields"
        :item="membershipForm"
        :get-options="getOptions"
      />

      <form-builder
        v-else
        :fields="fields"
        :item="membershipForm"
        @submit="saveMemberData"
        :get-rules="getRules"
        :get-options="getOptions"
        :loading="loading"
      />
    </v-card>
    <generic-alert ref="g_alert" />
  </Fragment>
</template>
<script>
import FilterMembers from "@/components/FilterMembers.vue";
import { debounce } from "debounce";
import { Fragment } from "vue-frag";
import EventBus from "../plugins/event-bus";
import {
  fields,
  headers,
  import_fields,
  email_regex,
  phone_regex,
  date_regex,
  name_regex,
} from "../plugins/membership";
import { capitalCase } from "change-case";
import FormBuilder from "../components/FormBuilder.vue";
import ViewBuilder from "../components/ViewBuilder.vue";
import GenericAlert from "../components/GenericAlert.vue";
import ImportData from "../components/ImportData.vue";

import importStatuses from "@/mixins/importStatuses.js";
import ConfirmAction from "../components/ConfirmAction.vue";
console.log("import_fields", import_fields);

export default {
  components: {
    FormBuilder,
    ViewBuilder,
    GenericAlert,
    Fragment,
    ImportData,
    ConfirmAction,
    FilterMembers,
  },
  name: "Membership",
  mixins: [importStatuses],
  data: () => {
    return {
      valid: false,
      membershipForm: null,

      fields,
      headers,
      selectedMembers: [],
      isFilterDialogVisible: false,
      isView: true,
      search: "",
      filters: { groups: [], statuses: [], skills: [] },
      isImportMembersVisible: false,
      import_fields,
      ///////////////
      confirmText: null,
      confirmTitle: null,
      newMemberStatus: null,
      confirmDialog: false,
    };
  },
  filters: {
    filterStatusIcon(status) {
      switch (status) {
        case "incomplete":
          return "mdi-exclamation-thick";
        case "pending":
          return "mdi-check-underline";
        case "confirmed":
          return "mdi-check";
        case "suspended":
          return "mdi-flag";
      }
    },
    filterComments(status) {
      switch (status) {
        case "incomplete":
          return "Switch to Incomplete";
        case "pending":
          return "Switch to Pending";
        case "confirmed":
          return "Confirm Member";
        case "suspended":
          return "Suspend Member";
      }
    },
  },
  computed: {
    title() {
      return this.isList
        ? "Members"
        : this.isView
        ? "Selected Member"
        : "Refer Member";
    },
    canEdit() {
      return (
        this.membershipForm.status == "incomplete" &&
        (this.is_admin || this.membershipForm.referred_by == this.username)
      );
    },
    validStatuses() {
      const statuses = [];
      const {
        isView,
        username,
        is_admin,
        membershipForm: { status, referred_by },
      } = this;

      if (isView) {
        if (is_admin) {
          if (status == "pending") statuses.push("incomplete", "confirmed");
          else if (status == "incomplete") statuses.push("pending");
          else if (status == "subscribed") statuses.push("suspended");
        } else if (username == referred_by && status == "incomplete")
          statuses.push("pending");
      }
      return statuses;
    },
    username() {
      return this.$store.getters["account/username"];
    },

    statuses() {
      return this.is_admin
        ? ["pending", "confirmed", "subscribed", "suspended"]
        : ["confirmed", "subscribed"];
    },

    groups() {
      return this.$store.getters["community/access_rules"];
    },

    isList() {
      return !this.membershipForm;
    },

    skills() {
      return this.$store.getters["community/skills"];
    },
    members() {
      return this.$store.state.member.members;
    },
    member() {
      return this.$store.state.member.member;
    },
    loading() {
      return this.$store.state.member.loading;
    },
    error() {
      return this.$store.state.member.error;
    },
    is_admin() {
      return this.$store.getters["community/is_admin"];
    },
    communityTitle() {
      return this.$store.getters["community/communityTitle"];
    },
  },
  watch: {
    filters: {
      handler: function (filters) {
        this.filters = filters;
        this.searchMembers();
      },
      deep: true,
    },
    isView: function (v) {
      console.log("watch isView", v);
    },
    member: {
      handler: function (m) {
        if (m) {
          console.log("watch member", m);
          this.membershipForm = { ...m };
          this.membershipForm.profession = m.profession && m.profession.id;
          this.membershipForm.skills = m.skills.map((x) => x.id);
          this.membershipForm.groups = m.groups.map((x) => x.id);
          this.membershipForm.emails = m.emails.join(",");
          this.membershipForm.phones = m.phones.join(",");
          console.log("membershipForm", this.membershipForm);
        } else {
          this.membershipForm = null;
        }
      },
      deep: true,
    },
    error: function (err) {
      const { g_alert } = this.$refs;
      if (err && g_alert) {
        console.error("membership err", err);
        g_alert.setGraphQlError(err);
      }
    },
    "$refs.g_alert": function (g_alert) {
      if (g_alert && this.error) {
        g_alert.setGraphQlError(this.error);
      }
    },
    search: debounce(function () {
      this.searchMembers();
    }, 500),
  },
  created() {
    this.searchMembers();
  },
  mounted() {
    console.log("skills", this.skills);
    console.log("groups", this.groups);
    EventBus.$emit("toggle-drawer");
  },
  methods: {
    checkRow(evt) {
      console.log("row clicked", evt);
    },
    setConfirmStatus(status) {
      console.log("status", status);
      this.newMemberStatus = status;
      switch (status) {
        case "incomplete":
          this.confirmText =
            "Change Status to 'Incomplete'. Allow referrer to correct Information.";
          this.confirmTitle = "Status Change";
          break;
        case "pending":
          this.confirmText =
            "Change Status to 'Pending'. Allow Admin to confirm Member.";
          this.confirmTitle = "Status Change";
          break;
        case "confirmed":
          this.confirmText =
            "Change Status to 'Confirmed'! Member will be notified";
          this.confirmTitle = "Status Change";
          break;
        case "suspended":
          this.confirmText = "Change Status to 'Suspended'!";
          this.confirmTitle = "Status Change";
          break;
      }
      this.confirmDialog = true;
    },
    changeMemberStatus(newStatus) {
      console.log("member", this.membershipForm);
      const { id } = this.membershipForm;
      this.$store.dispatch("member/changeMemberStatus", { id, newStatus });
      this.confirmDialog = false;
    },

    searchMembers() {
      const { search, filters } = this;
      console.log("filters", filters);
      this.$store.dispatch("member/listMembers", {
        search,
        ...filters,
      });
    },
    openSideBar() {
      EventBus.$emit("toggle-drawer");
    },
    openNewForm() {
      this.isView = false;
      this.membershipForm = {};
    },
    selectMember(item) {
      console.log("handleclick item", item);
      this.isView = true;
      this.$store.dispatch("member/getMember", item.id);
    },
    saveMemberData(evt) {
      const {
        membershipForm: { id },
      } = this;
      const data = { ...evt };
      data.emails = (data.emails || "").split(",");
      data.phones = (data.phones || "").split(",");
      if (id) data.id = id;
      console.log("new data-formatted", data);
      const action = id ? "updateMember" : "referMember";
      console.log("action", action);
      this.$store.dispatch(`member/${action}`, data);
    },

    async uploadProspectiveMembers(originalData, defaults, setStatus) {
      const data = { ...originalData };

      try {
        data.emails = (data.emails || "").split(",");
        data.phones = (data.phones || "").split(",");
        data.isImport = true;
        console.log("data-formatted", data);

        await this.nextTick();
        const messages = [];
        this.validateMemberImport(data, defaults, messages);
        console.log("messages", messages);
        if (messages.length > 0) throw messages;
        setStatus(this.import_statuses.SAVING);
        console.log("dataRecord->", data);
        await this.$store.dispatch("member/referMember", data);
        setStatus(this.import_statuses.COMPLETE);
      } catch (err) {
        console.log("error", err);
        const messages = [];

        if (Array.isArray(err)) messages.push(...err);
        else if (err.message) messages.push(err.message);
        else messages.push(...err.errors.map((e) => e.message));

        console.log("error messages", messages);
        setStatus(this.import_statuses.ERROR, messages);
      }
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    nextTick() {
      const $this = this;
      return new Promise((resolve) => $this.$nextTick(resolve));
    },

    getOptions(key) {
      switch (key) {
        case "gender":
          return "Male,Female".split(",").map((x) => ({ value: x, text: x }));
        case "honorific":
          return "Dr,Prof,Mr,Mrs,Ms"
            .split(",")
            .map((x) => ({ value: x, text: x }));
        case "profession":
        case "skills":
          return this.skills.map((x) => ({
            value: x.id,
            text: x.name,
          }));
        case "groups":
          return this.groups.map((x) => ({
            value: x.group_id,
            text: x.group_name,
          }));
        case "date_from":
        case "date_to":
        case "birth_date":
        case "emails":
        default:
          return [];
      }
    },

    getRules(key) {
      switch (key) {
        case "honorific":
        case "gender":
          return [(v) => !!v || `${capitalCase(key)} is required`];
        case "emails":
          return [
            (v) => !!v || "At least One E-mail Address is required",
            (v) =>
              email_regex.test(v) ||
              "E-mail must be valid. Seperate Multiple Emails with commas",
          ];
        case "phones":
          return [
            (v) => !!v || "At least One Phone Number is required",
            (v) =>
              phone_regex.test(v) ||
              "Phone must be valid. Separate Multiple Phone Numbers with commas",
          ];
        case "date_from":
        case "date_to":
        case "birth_date":
          return [
            (v) => !!v || `${capitalCase(key)} is required`,
            (v) => date_regex.test(v) || `Invalid ${capitalCase(key)}`,
          ];
        case "first_name":
        case "last_name":
          return [
            (v) => !!v || `${capitalCase(key)} is required`,
            (v) => name_regex.test(v) || `${capitalCase(key)} is invalid`,
          ];
        default:
          return [];
      }
    },

    validateMemberImport(data, defaults, messages) {
      for (const key in data) {
        const v = data[key];
        const options = this.getOptions(key);
        const isValid = options.some((x) => x == v);
        const defaultValue = defaults.find((x) => x.name == key);
        switch (key) {
          case "honorific":
          case "gender":
            {
              if (!isValid && defaultValue) {
                data[key] = defaultValue.value;
              } else if (!isValid) {
                messages.push(
                  `${capitalCase(
                    key
                  )} is invalid! Valid Values are ${options.join()}`
                );
              }
            }
            break;
          case "emails":
            {
              const validEmails = [];
              for (const e of v) {
                if (email_regex.test(e)) {
                  validEmails.push(e);
                }
              }
              data[key] = validEmails;
              if (validEmails.length == 0)
                messages.push(`Valid E-mail Address is required`);
            }
            break;
          case "phones":
            {
              const validPhones = [];
              for (const e of v) {
                if (e && phone_regex.test(e)) {
                  validPhones.push(e);
                }
              }
              data[key] = validPhones;
            }
            break;
          case "date_from":
          case "date_to":
            {
              if (!date_regex.test(v)) {
                if (!isValid && defaultValue) {
                  data[key] = defaultValue.value;
                } else if (!isValid) {
                  data[key] = null;
                }
              }
            }
            break;
          case "birth_date":
            {
              if (!date_regex.test(v)) data[key] = null;
            }
            break;
          case "first_name":
          case "last_name":
            if (!v) messages.push(`${capitalCase(key)} is required`);
            else if (!name_regex.test(v))
              messages.push(`Invalid ${capitalCase(key)}`);
            break;
        }
      }
    },
  },
};
</script>
