var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cafe-pdf',{ref:"pdf",attrs:{"filePath":_vm.src},on:{"on-success":_vm.load,"on-scroll":_vm.scroll}},[_c('template',{slot:"header"},[_c('div',{attrs:{"data-app":""}},[_c('v-toolbar',{attrs:{"width":"100%"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-multiple")])],1)]}}]),model:{value:(_vm.documentListMenu),callback:function ($$v) {_vm.documentListMenu=$$v},expression:"documentListMenu"}},[(_vm.documentItems.length == 0)?_c('v-list-item',[_c('v-list-item-title',[_vm._v("No Documents Loaded!")])],1):_vm._e(),_c('v-list',_vm._l((_vm.documentItems),function(group,index){return _c('v-list-group',{key:("docments-" + index),attrs:{"append-icon":_vm.dtoggleList[index] ? 'mdi-folder-open' : 'mdi-folder'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(group.name))])]},proxy:true}],null,true),model:{value:(_vm.dtoggleList[index]),callback:function ($$v) {_vm.$set(_vm.dtoggleList, index, $$v)},expression:"dtoggleList[index]"}},_vm._l((group.children),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){$event.stopPropagation();item.isFile && _vm.setDocument(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)],1)}),1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue darken-2"}},[_vm._v("mdi-bookmark")])],1)]}}])},[_c('v-list',[_vm._l((_vm.selectedLinks),function(link){return _c('v-list-item',{key:link.title,attrs:{"link":""},on:{"click":function($event){return _vm.pageChange(link.page_no)}}},[_c('v-list-item-title',[_vm._v(_vm._s(link.title))])],1)}),(_vm.selectedLinks.length == 0)?_c('v-list-item',[_c('v-list-item-title',[_vm._v("No Bookmark Defined!")])],1):_vm._e()],2)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crop-free")])],1)]}}])},[_c('v-list',_vm._l((_vm.scales),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.scale = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('exit')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]),_c('template',{slot:"footer"},[_c('div',{attrs:{"data-app":""}},[_c('footer-page-navigator',{attrs:{"current-num":_vm.currentNum,"page-num":_vm.pageNum},on:{"set-prev-page":_vm.setPrevPage,"set-next-page":_vm.setNextPage,"set-first-page":function($event){return _vm.pageChange(1)},"set-last-page":function($event){return _vm.pageChange(_vm.pageNum)}}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }