/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addSkill = /* GraphQL */ `
  mutation AddSkill(
    $community_id: String!
    $description: String
    $name: String!
  ) {
    addSkill(
      community_id: $community_id
      description: $description
      name: $name
    ) {
      description
      id
      name
    }
  }
`;
export const changeMemberStatus = /* GraphQL */ `
  mutation ChangeMemberStatus(
    $community_id: String!
    $id: ID!
    $newStatus: MemberStatus
  ) {
    changeMemberStatus(
      community_id: $community_id
      id: $id
      newStatus: $newStatus
    ) {
      birth_date
      date_from
      date_to
      emails
      first_name
      gender
      groups {
        id
        is_admin
        name
      }
      honorific
      id
      last_name
      middle_name
      nick_name
      phones
      profession {
        description
        id
        name
      }
      referred_by
      skills {
        description
        id
        name
      }
      status
      username
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($input: DocumentInput) {
    deleteDocument(input: $input) {
      file_name
      group {
        id
        is_admin
        name
      }
      key
      links {
        page_no
        title
      }
      title
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill($community_id: String!, $id: ID!) {
    deleteSkill(community_id: $community_id, id: $id) {
      description
      id
      name
    }
  }
`;
export const modifyDocumentMeta = /* GraphQL */ `
  mutation ModifyDocumentMeta($input: DocumentInput) {
    modifyDocumentMeta(input: $input) {
      file_name
      group {
        id
        is_admin
        name
      }
      key
      links {
        page_no
        title
      }
      title
    }
  }
`;
export const referMember = /* GraphQL */ `
  mutation ReferMember($input: MemberInput) {
    referMember(input: $input) {
      birth_date
      date_from
      date_to
      emails
      first_name
      gender
      groups {
        id
        is_admin
        name
      }
      honorific
      id
      last_name
      middle_name
      nick_name
      phones
      profession {
        description
        id
        name
      }
      referred_by
      skills {
        description
        id
        name
      }
      status
      username
    }
  }
`;
export const setCommunity = /* GraphQL */ `
  mutation SetCommunity($id: String) {
    setCommunity(id: $id) {
      access_rules {
        email_regex
        group_id
        group_name
        is_member
      }
      admin_list {
        birth_date
        date_from
        date_to
        emails
        first_name
        gender
        groups {
          id
          is_admin
          name
        }
        honorific
        id
        last_name
        middle_name
        nick_name
        phones
        profession {
          description
          id
          name
        }
        referred_by
        skills {
          description
          id
          name
        }
        status
        username
      }
      document_metas {
        file_name
        group {
          id
          is_admin
          name
        }
        key
        links {
          page_no
          title
        }
        title
      }
      selected_community {
        id
        is_admin
        name
      }
      skills {
        description
        id
        name
      }
      user {
        email
        username
      }
      user_communities {
        id
        is_admin
        name
      }
    }
  }
`;
export const setupCommunity = /* GraphQL */ `
  mutation SetupCommunity($input: CommunityInput) {
    setupCommunity(input: $input) {
      access_rules {
        email_regex
        group_id
        group_name
        is_member
      }
      admin_list {
        birth_date
        date_from
        date_to
        emails
        first_name
        gender
        groups {
          id
          is_admin
          name
        }
        honorific
        id
        last_name
        middle_name
        nick_name
        phones
        profession {
          description
          id
          name
        }
        referred_by
        skills {
          description
          id
          name
        }
        status
        username
      }
      document_metas {
        file_name
        group {
          id
          is_admin
          name
        }
        key
        links {
          page_no
          title
        }
        title
      }
      selected_community {
        id
        is_admin
        name
      }
      skills {
        description
        id
        name
      }
      user {
        email
        username
      }
      user_communities {
        id
        is_admin
        name
      }
    }
  }
`;
export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity($input: CommunityInput) {
    updateCommunity(input: $input) {
      access_rules {
        email_regex
        group_id
        group_name
        is_member
      }
      admin_list {
        birth_date
        date_from
        date_to
        emails
        first_name
        gender
        groups {
          id
          is_admin
          name
        }
        honorific
        id
        last_name
        middle_name
        nick_name
        phones
        profession {
          description
          id
          name
        }
        referred_by
        skills {
          description
          id
          name
        }
        status
        username
      }
      document_metas {
        file_name
        group {
          id
          is_admin
          name
        }
        key
        links {
          page_no
          title
        }
        title
      }
      selected_community {
        id
        is_admin
        name
      }
      skills {
        description
        id
        name
      }
      user {
        email
        username
      }
      user_communities {
        id
        is_admin
        name
      }
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember($input: MemberInput) {
    updateMember(input: $input) {
      birth_date
      date_from
      date_to
      emails
      first_name
      gender
      groups {
        id
        is_admin
        name
      }
      honorific
      id
      last_name
      middle_name
      nick_name
      phones
      profession {
        description
        id
        name
      }
      referred_by
      skills {
        description
        id
        name
      }
      status
      username
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings($input: importSettingsInput) {
    updateSettings(input: $input) {
      columns {
        name
        position
      }
      community_id
      defaults {
        name
        value
      }
      row_no
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $community_id: String!
    $description: String
    $id: ID!
    $name: String
  ) {
    updateSkill(
      community_id: $community_id
      description: $description
      id: $id
      name: $name
    ) {
      description
      id
      name
    }
  }
`;
export const uploadPDF = /* GraphQL */ `
  mutation UploadPDF($input: DocumentInput) {
    uploadPDF(input: $input) {
      file_name
      group {
        id
        is_admin
        name
      }
      key
      links {
        page_no
        title
      }
      title
    }
  }
`;
