<template>
  <v-dialog v-model="dialogIsVisible" scrollable max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on"></slot>
    </template>

    <v-card>
      <v-card-title
        >{{ title }}

        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 1000px">
        <slot />
        <slot name="display" v-if="showItem"></slot>
        <slot name="list" v-else>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ listLabel }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn small class="mb-2" @click="viewItem()">
                  {{ addNewLabel }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="viewItem(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeDialog">
          {{ closeLabel }}
        </v-btn>

        <v-btn
          color="red"
          text
          v-if="showDeleteButton"
          @click="deleteDialog"
          :loading="deleting"
          >Delete</v-btn
        >
        <v-btn
          v-if="showItem && showSaveButton"
          color="primary"
          :loading="loading"
          @click="saveDialog"
        >
          {{ saveLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "../plugins/event-bus";
export default {
  name: "GenericDialog",
  data: () => ({
    dialogIsVisible: false,
  }),

  props: {
    items: {
      default: () => [],
      type: Array,
    },
    headers: {
      default: () => [],
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    deleting: {
      default: false,
      type: Boolean,
    },

    showSaveButton: {
      default: true,
      type: Boolean,
    },
    showItem: {
      default: false,
      type: Boolean,
    },

    showDeleteButton: {
      default: false,
      type: Boolean,
    },

    title: {
      default: "",
      type: String,
    },

    listLabel: {
      default: "",
      type: String,
    },
    addNewLabel: {
      default: "Add",
      type: String,
    },
    closeLabel: {
      default: "Close",
      type: String,
    },
    saveLabel: {
      default: "Save",
      type: String,
    },
  },

  computed: {},
  watch: {
  },
  mounted() {
    EventBus.$on("generic-dialog-state", (_state) => {
      this.dialogIsVisible = _state;
    });
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
    saveDialog() {
      this.$emit("save");
    },

    viewItem(item) {
      this.$emit("display", item);
    },

    deleteDialog() {
      this.$emit("delete");
    },
  },
};
</script>