/**
 * The possible statuses during upload
 */
const import_statuses = {
  COMPLETE: "complete",
  ERROR: "errors",
  PENDING: "ready",
  CHECKING: " checking",
  SAVING: "saving",
};
export default {
  data: () => ({
    import_statuses,
  }),
};
