<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-for="item in fields.filter((x) => x.visible)"
        :key="item.key"
      >
        <v-text-field
          v-if="item.type == 'multiple'"
          :value="getText(item.key, true)"
          :label="item.label"
          readonly
        ></v-text-field>
        <v-text-field
          v-else-if="item.type == 'select'"
          :value="getText(item.key)"
          :label="item.label"
          readonly
        ></v-text-field>
        <v-checkbox
          v-else-if="item.type == 'check'"
          :value="dataForm[item.key]"
          :label="item.label"
          readonly
        ></v-checkbox>
        <v-text-field
          v-else
          :type="item.type"
          :value="dataForm[item.key] || '-'"
          :label="item.label"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "FormControl",
  props: ["item", "fields", "getOptions"],

  data: () => ({
    valid: true,
    dataForm: {},
  }),

  computed: {},

  mounted() {
    this.dataForm = this.fields.reduce((obj, item) => {
      obj[item.key] = this.item[item.key] || null;
      return obj;
    }, {});
  },

  methods: {
    getText(key, isMultiple) {
      const options = this.getOptions(key);
      const value = this.dataForm[key];
      if (isMultiple)
        return (value || [])
          .map((_value) => options.find((x) => x.value == _value).text)
          .join(", ");
      else return (options.find((x) => x.value == value) || {}).text || "-";
    },
  },
};
</script>