<template>
  <generic-dialog
    @input="exitDisplay"
    @display="viewGroup"
    @delete="submitAccessGroups(true)"
    @save="submitAccessGroups()"
    :items="accessRules"
    :headers="headers"
    :loading="loading"
    :deleting="deleting"
    :title="title"
    :show-item="showItem"
    :list-label="listLabel"
    :add-new-label="addNewLabel"
    :close-label="closeLabel"
    :save-Label="saveLabel"
    :show-delete-button="showDeleteButton"
  >
    <template #activator="{ attrs, on }">
      <slot :attrs="attrs" :on="on" name="activator" />
    </template>
    <template #display>
      <v-form
        v-if="showItem"
        @submit.prevent.stop="submitDocument()"
        ref="group-model"
        lazy-validation
      >
        <v-card flat height="250px">
          <v-text-field
            v-model="groupModel.group_name"
            :rules="[(v) => !!v || 'Name is required']"
            placeholder="Group Name"
            required
            label="Group Name"
          ></v-text-field>

          <v-text-field
            v-model="groupModel.email_regex"
            label="Email Regex"
          ></v-text-field>
        </v-card>
      </v-form>
    </template>
    <template #default>
      <generic-alert ref="g_alert" />
    </template>
  </generic-dialog>
</template>

<script>
import EventBus from "../plugins/event-bus";
import GenericAlert from "../components/GenericAlert.vue";
import GenericDialog from "../components/GenericDialog.vue";
export default {
  name: "ManageGroups",
  components: { GenericDialog, GenericAlert },
  props: ["accessRules"],
  data: () => ({
    listLabel: "Groups",
    addNewLabel: "Add Group",
    closeLabel: "Close",
    saveLabel: "Save",
    document: null,
    loading: false,
    deleting: false,
    groupModel: null,
    headers: [
      // { text: "id", value: "group_id", align: "start" },
      {
        text: "Name",
        value: "group_name",
      },
      {
        text: "Email Regex",
        value: "email_regex",
      },
      /*  {
        text: "Date Started",
        value: "started",
      },
      {
        text: "Date Completed",
        value: "completed",
      }, */
      { text: "Edit", value: "actions", sortable: false },
    ],
  }),
  computed: {
    showItem() {
      return !!this.groupModel;
    },
    showDeleteButton() {
      return !!(this.groupModel && this.groupModel.id);
    },
    title() {
      if (!this.groupModel) return "Manage Groups";
      else if (this.groupModel.group_id) return "Edit Group";
      else return "Add New Group";
    },
  },
  watch: {},
  mounted() {
    console.log("access list mounted");
  },
  async created() {},
  methods: {
    exitDisplay() {
      if (this.showItem) {
        this.groupModel = null;
      } else {
        EventBus.$emit("generic-dialog-state", false);
      }
    },
    viewGroup(item) {
      console.log("item", item);
      if (item) {
        this.groupModel = { ...item };
        this.groupModel.email_regex = this.groupModel.email_regex || "";
      } else {
        this.groupModel = {
          group_name: "",
          email_regex: "",
        };
      }
    },
    async submitAccessGroups(is_group_delete = false) {
      try {
        this.loading = true;
        if (!this.$refs[`group-model`].validate())
          throw new Error("Invalid submission");

        const {
          group_id,
          group_name,
          email_regex: group_email_regex,
        } = this.groupModel;
        const params = {
          group_id,
          group_name,
          group_email_regex,
          is_group_delete,
        };
        await this.$store.dispatch("community/updateCommunity", params);
        this.groupModel = null;
      } catch (err) {
        console.error("submitAccessGroups err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
