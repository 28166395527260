<template>
  <v-dialog v-model="value" width="800">
    <v-card>
      <v-card-title
        >Filter Members
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="filters.groups"
                  :items="allGroups"
                  single-line
                  item-text="group_name"
                  item-value="group_id"
                  label="Groups"
                  multiple
                  hint="Select Groups"
                  persistent-hint
                  clearable
                  chips
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="filters.statuses"
                  :items="allStatuses"
                  single-line
                  label="Statuses"
                  multiple
                  hint="Select Statuses"
                  persistent-hint
                  clearable
                  chips
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="filters.skills"
                  :items="allSkills"
                  single-line
                  item-text="name"
                  item-value="id"
                  label="Skills"
                  multiple
                  hint="Select Professions/Skills"
                  persistent-hint
                  clearable
                  chips
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FilterMembers",
  data: () => ({}),

  props: {
    value: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    allGroups: {
      type: Array,
      require: true,
    },
    allStatuses: {
      type: Array,
      require: true,
    },
    allSkills: {
      type: Array,
      require: true,
    },
    filters: {
      type: Object,
      default: () => ({ groups: [], statuses: [], skills: [] }),
    },
  },

  computed: {},
  watch: {
    filters: {
      handler: function (filters) {
        this.$emit("update:filters", filters);
      },
      deep: true,
    },
  },
  mounted() {
    console.log("skills", this.allSkills);
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
  },
};
</script>