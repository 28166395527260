import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { capitalCase } from "change-case";
const KeyPrefx = "courserv";

const md5 = require("md5");
const STORAGE_PERIOD = 0; //1000 * 60 * 5; //5 minutes

class StorageClass {
  constructor({ user, community_domain }, s3Key, isRaw) {
    this.key = this.getKey({ user, community_domain }, s3Key);
    this.isRaw = isRaw;
  }

  getKey({ user, community_domain }, s3Key) {
    const username = (user && user.username) || "anonymous";
    const type = `${s3Key ? s3Key : "communityMeta"}`;
    const _key = [KeyPrefx, community_domain, username, type].join("-");
    console.log("key", _key);
    return _key;
  }

  checkCacheExpired() {
    console.log("time diff", Date.now() - this._time);
    return this._time < Date.now() - STORAGE_PERIOD;
  }

  getData() {
    const rawData = localStorage.getItem(this.key);
    if (rawData) {
      let parsedData = JSON.parse(rawData);
      ({ _time: this._time } = parsedData);
      if (this.isRaw) {
        return parsedData._raw;
      } else {
        delete parsedData._time;
        return parsedData;
      }
    }
    this._time = this._time || 0;
    return null;
  }

  setData(data) {
    try {
      const _time = Date.now();
      let parsedData = { _time };
      if (this.isRaw) {
        parsedData._raw = data;
      } else {
        parsedData = { ...parsedData, ...data };
      }
      localStorage.setItem(this.key, JSON.stringify(parsedData));
    } catch (err) {
      console.log("setData error", err);
      return null;
    }
  }

  //get from storage first.  get remote only if no data or expired
  async getCache({ commitFunc, params, graphqlQuery }) {
    let data = this.getData();

    if (data) {
      commitFunc(data);
    }
    if (!data || this.checkCacheExpired()) {
      console.log("checking api");
      const value = await API.graphql(
        graphqlOperation(queries[graphqlQuery], params)
      );
      data = value.data[graphqlQuery];
      commitFunc(data);
      this.setData(data);
    }
  }
}

const store = { namespaced: true };

// State ======================
store.state = {
  communityMeta: null,
  file_data: null,
  subscription: null,
};

// Getters ====================
store.getters = {
  communityTitle: (state) => {
    const community = state.communityMeta?.selected_community || {};
    return capitalCase((community && community.name) || "enriched community");
  },
  community: (state) => state.communityMeta?.selected_community || {},
  user_communities: (state) => state.communityMeta?.user_communities || [],
  admin_list: (state) => state.communityMeta?.admin_list || [],
  skills: (state) => state.communityMeta?.skills || [],
  access_rules: (state) => state.communityMeta?.access_rules || [],
  document_metas: (state) => state.communityMeta?.document_metas || [],
  is_admin: (state) => !!state.communityMeta?.selected_community?.is_admin,
  hashedFile: (state) => md5(state.file_data || ""),
};

// Mutations ==================
store.mutations = {
  SET_COMMUNITY(state, communityMeta) {
    state.communityMeta = communityMeta;
  },
  SET_FILE_DATA(state, file_data) {
    state.file_data = file_data;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

// Actions ====================
store.actions = {
  //get communityMeta
  async getCommunityMeta(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      getters,
    },
    id
  ) {
    const commitFunc = (_data) => {
      commit("SET_COMMUNITY", _data);
    };
    console.log("bingo");
    const community_id = id || getters["community"]?.id;
    community_domain = id ? null : community_domain;
    const input = { community_id, community_domain };
    const params = { input };
    const graphqlQuery = "getCommunityMeta";
    const storage = new StorageClass({ user, community_domain }, null, false);
    await storage.getCache({ commitFunc, params, graphqlQuery });
  },

  async setupCommunity(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
    },
    { community_name }
  ) {
    const input = {
      community_domain,
      community_name,
    };
    const {
      data: { setupCommunity: domainMeta },
    } = await API.graphql(
      graphqlOperation(mutations.setupCommunity, {
        input,
      })
    );

    const storage = new StorageClass({ community_domain, user }, null, false);
    storage.setData(domainMeta);
    commit("SET_COMMUNITY", domainMeta);
  },

  async updateCommunity(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      getters,
    },
    //{ add_by_username, delete_by_id, access_rules }
    {
      group_id,
      group_name,
      group_email_regex,
      member_add_by_username,
      member_delete_by_id,
    }
  ) {
    const community_id = getters["community"]?.id;
    const input = {
      community_domain,
      community_id,
      group_id,
      group_name,
      group_email_regex,
      member_add_by_username,
      member_delete_by_id,
    };
    const {
      data: { updateCommunity: domainMeta },
    } = await API.graphql(
      graphqlOperation(mutations.updateCommunity, {
        input,
      })
    );
    const storage = new StorageClass({ community_domain, user }, null, false);
    storage.setData(domainMeta);
    commit("SET_COMMUNITY", domainMeta);
  },

  async uploadPDF(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      state,
      getters,
    },
    { links, group_id, file_data, file_name, title, content_type }
  ) {
    links = JSON.stringify(links);
    const community_id = getters["community"].id;
    const input = {
      community_id,
      links,
      group_id,
      file_data,
      file_name,
      title,
      content_type,
    };

    console.log("upload input", input);

    const {
      data: { uploadPDF: documentMeta },
    } = await API.graphql(
      graphqlOperation(mutations.uploadPDF, {
        input,
      })
    );
    console.log("uploadPDF:", documentMeta);
    const communityMeta = { ...state.communityMeta };
    console.log("communityMeta", communityMeta);
    communityMeta.document_metas.push(documentMeta);
    const storage = new StorageClass({ community_domain, user }, null, false);
    storage.setData(communityMeta);
    commit("SET_COMMUNITY", communityMeta);
  },

  async modifyDocumentMeta(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      state,
      getters,
    },
    { group_id, key, title, links }
  ) {
    links = JSON.stringify(links);
    const community_id = getters["community"]?.id;
    const input = {
      community_id,
      group_id,
      links,
      key,
      title,
    };
    const {
      data: { modifyDocumentMeta: documentMeta },
    } = await API.graphql(
      graphqlOperation(mutations.modifyDocumentMeta, {
        input,
      })
    );

    console.log("modifyDocumentMeta:", documentMeta);
    const communityMeta = { ...state.communityMeta };
    const idx = communityMeta.document_metas.findIndex(
      (x) => x.key === documentMeta.key
    );
    communityMeta.document_metas.splice(idx, 1, documentMeta);

    const storage = new StorageClass({ community_domain, user }, null, false);
    storage.setData(communityMeta);
    commit("SET_COMMUNITY", communityMeta);
  },

  async deleteDocument(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      state,
      getters,
    },
    { group_id, key, title }
  ) {
    console.log("vuex key", key);
    const community_id = getters["community"]?.id;
    const input = { group_id, title, community_id, key };
    await API.graphql(
      graphqlOperation(mutations.deleteDocument, {
        input,
      })
    );
    const { communityMeta } = state;
    const idx = communityMeta.document_metas.findIndex((x) => x.key === key);
    communityMeta.document_metas.splice(idx, 1);
    const storage = new StorageClass({ community_domain, user }, null, false);
    storage.setData(communityMeta);
    commit("SET_COMMUNITY", communityMeta);
  },

  async getBase64Document(
    {
      commit,
      rootState: {
        community_domain,
        account: { user },
      },
      getters,
    },
    key
  ) {
    const commitFunc = (_data) => {
      commit("SET_FILE_DATA", _data);
    };
    const community_id = getters["community"]?.id;
    const params = {
      community_id,
      key,
    };
    const graphqlQuery = "getBase64Document";
    const storage = new StorageClass({ community_domain, user }, key, true);
    await storage.getCache({ commitFunc, params, graphqlQuery });
  },

  onCommunityUpdate(
    {
      commit,
      state,
      rootState: {
        community_domain,
        account: { user },
      },
    },
    id
  ) {
    if (state.subscription) state.subscription.unsubscribe();

    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCommunityUpdate, { id })
    ).subscribe({
      next: ({ value: { data, errors } }) => {
        if (errors) {
          console.log("", errors);
        } else {
          console.log("onCommunityUpdate bingo");

          const communityMeta = data.onCommunityUpdate;

          const {
            selected_community: { id: _id },
          } = communityMeta;

          if (id && id == _id) {
            commit("SET_COMMUNITY", communityMeta);
            const storage = new StorageClass(
              { user, community_domain },
              null,
              false
            );
            storage.setData(communityMeta);
          }
        }
      },
    });
    commit("SET_SUBSCRIPTION", subscription);
  },
};

// Export =====================
export default store;
