var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"absolute":"","temporary":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Menu ")]),_c('v-list-item-subtitle')],1)],1),_c('v-divider'),(_vm.authorized)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Community")]),_c('v-expansion-panel-content',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"2","loading":_vm.loading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.communityTitle)+" ")])]}}],null,false,1520770148)},[_c('v-list',_vm._l((_vm.user_communities),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.switchCommunity(item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Admin")]),_c('v-expansion-panel-content',[_c('v-list',[_c('manage-communities',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Manage Communities")])],1)],1)]}}],null,false,3640719108)}),(_vm.is_admin)?[_c('manage-admin',{attrs:{"admin-list":_vm.admin_list},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Manage Administrators")])],1)],1)]}}],null,false,1483699289)}),_c('manage-skills',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Manage Skills")])],1)],1)]}}],null,false,2052199923)}),_c('manage-groups',{attrs:{"access-rules":_vm.access_rules},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Manage Groups")])],1)],1)]}}],null,false,3181072701)}),_c('manage-documents',{attrs:{"document-metas":_vm.document_metas,"groups":_vm.access_rules || []},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Manage Documents")])],1)],1)]}}],null,false,4108643277)})]:_vm._e()],2)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Applications")]),_c('v-expansion-panel-content',[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.viewDocuments}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Document Viewer")])],1)],1),_vm._l((_vm.apps),function(app){return _c('v-list-item',{key:app.type,attrs:{"link":""},on:{"click":function($event){return _vm.gotoApp(app.type)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(app.name))])],1)],1)})],2)],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v("Loading Document ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }