import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
//import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const formatColumnsArray = (columns_map) => {
  const arr = [];
  for (const name in columns_map) {
    const position = columns_map[name];
    if (typeof position == undefined || position == null || position == -1) {
      continue;
    }
    arr.push({ name, position });
  }
  return arr;
};

const store = { namespaced: true };

// State ======================
store.state = {
  userSettings: { row_no: null, columns: [] },
  loading: false,
  error: null,
};

// Getters ====================
store.getters = {
  columns_map: (state) => {
    let { columns } = state.userSettings || {};
    columns = columns || [];
    return columns.reduce((_map, { name, position }) => {
      _map[name] = position;
      return _map;
    }, {});
  },
  row_no: (state) => state.userSettings.row_no,
};

// Mutations ==================
store.mutations = {
  SET_SETTINGS(state, userSettings) {
    state.userSettings = userSettings;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

// Actions ====================
store.actions = {
  //get communityMeta
  async getSettings({ commit, rootGetters }) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;
      const {
        data: { getSettings: userSettings },
      } = await API.graphql(
        graphqlOperation(queries.getSettings, {
          community_id,
        })
      );
      commit("SET_SETTINGS", userSettings);
    } catch (err) {
      console.error("getSettings error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async updateSettings(
    { commit, rootGetters },
    { columns_map, row_no, defaults }
  ) {
    try {
      commit("SET_ERROR", null);
      commit("SET_LOADING", true);
      const community_id = rootGetters["community/community"]?.id;
      const columns = JSON.stringify(formatColumnsArray(columns_map));
      defaults = JSON.stringify(defaults);
      row_no = row_no || 1;
      const input = {
        community_id,
        columns,
        row_no,
        defaults,
      };
      console.log("input", input);
      const {
        data: { updateSettings: userSettings },
      } = await API.graphql(
        graphqlOperation(mutations.updateSettings, {
          input,
        })
      );
      commit("SET_SETTINGS", userSettings);
    } catch (err) {
      console.error("updateSettings error:", err);
      commit("SET_ERROR", err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

// Export =====================
export default store;
