<template>
  <v-snackbar v-model="isAlert" :timeout="timeout">
    {{ alertMessage }}

    <template v-slot:action="{ attrs }">
      <v-btn :color="alertType" icon v-bind="attrs" @click="isAlert = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    alertMessage: null,
    alertType: "warning",
    isAlert: false,
    timeout: 15000,
  }),

  methods: {
    setAlert(message, isSuccess = true) {
      this.isAlert = !!message;
      this.alertType = isSuccess ? "success" : "warning";
      this.alertMessage = message;
    },
    setGraphQlError(err) {
      console.log("setGraphQlError", err);
      this.isAlert = true;
      this.alertType = "warning";
      this.alertMessage =
        "Error!--> " +
        (typeof err == "string"
          ? err
          : err.message
          ? err.message
          : (err.errors || []).reduce(
              (message, item, i) =>
                (message += (i === 0 ? "" : "--") + item.message),
              ""
            ));
    },
  },
};
</script>