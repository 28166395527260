<template>
  <generic-dialog
    @input="exitDisplay"
    @display="viewAdmin"
    @save="submitChanges"
    @delete="submitChanges(true)"
    :items="adminList"
    :headers="headers"
    :loading="loading"
    :deleting="deleting"
    :title="title"
    :show-item="showItem"
    :list-label="listLabel"
    :add-new-label="addNewLabel"
    :close-label="closeLabel"
    :save-Label="saveLabel"
    :show-delete-button="showDeleteButton"
    :show-save-button="showSaveButton"
  >
    <template #default>
      <generic-alert ref="g_alert" />
    </template>
    <template #activator="{ attrs, on }">
      <slot :attrs="attrs" :on="on" name="activator" />
    </template>
    <template #display>
      <v-form
        v-if="showItem"
        @submit.prevent.stop="submitChanges()"
        ref="form-document"
        lazy-validation
      >
        <v-card flat height="250px">
          <v-text-field
            v-model="adminModel.username"
            :rules="[(v) => !!v || 'Admin UserName is required']"
            placeholder="UserName"
            required
          ></v-text-field>
        </v-card>
      </v-form> </template
  ></generic-dialog>
</template>

<script>
import EventBus from "../plugins/event-bus";
import GenericAlert from "../components/GenericAlert.vue";
import GenericDialog from "../components/GenericDialog.vue";
export default {
  components: { GenericDialog, GenericAlert },
  name: "ManageGroupAdministrators",
  props: ["adminList"],
  data: () => ({
    ////////////////
    listLabel: "List",
    addNewLabel: "Add",
    closeLabel: "Close",
    saveLabel: "Save",
    ///////////////
    headers: [
      {
        text: "First Name",
        value: "first_name",
      },
      {
        text: "Last Name",
        value: "last_name",
      },
      {
        text: "Username",
        value: "username",
      },

      { text: "Edit", value: "actions", sortable: false },
    ],
    loading: false,
    deleting: false,
    adminModel: null,
  }),
  computed: {
    showItem() {
      return !!this.adminModel;
    },
    showDeleteButton() {
      return !!(this.adminModel && this.adminModel.id);
    },
    showSaveButton() {
      return !(this.adminModel && this.adminModel.id);
    },
    title() {
      if (this.adminModel == null) return "Manage Admin";
      else if (this.adminModel.id) return "Edit Admin";
      else return "Add New Admin";
    },
  },

  mounted() {
    const { adminList } = this;
    console.log({ adminList });
  },
  async created() {},
  methods: {
    exitDisplay() {
      if (this.showItem) {
        this.adminModel = null;
      } else {
        EventBus.$emit("generic-dialog-state", false);
      }
    },
    viewAdmin(item) {
      console.log("item", item);
      if (item) {
        this.adminModel = { ...item };
      } else {
        this.adminModel = {
          id: null,
          username: null,
        };
      }
    },
    async submitChanges(isDelete = false) {
      try {
        this.loading = true;

        const params = {};
        const { id, username } = this.adminModel;
        console.log("isDelete", isDelete);
        if ((id && isDelete) || !id) {
          if (id) {
            params.member_id = id;
            params.is_member_delete = true;
          } else {
            params.member_username = username;
          }
          await this.$store.dispatch("community/updateCommunity", params);
        } else {
          throw new Error("No Changes submitted!");
        }
      } catch (err) {
        console.error("err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
