<template>
  <generic-dialog
    @input="exitDisplay"
    @display="viewCommunity"
    @save="submitCommunities()"
    :items="user_communities"
    :headers="headers"
    :loading="loading"
    :deleting="deleting"
    :title="title"
    :show-item="showItem"
    :list-label="listLabel"
    :add-new-label="addNewLabel"
    :close-label="closeLabel"
    :save-Label="saveLabel"
    :show-delete-button="showDeleteButton"
  >
    <template #activator="{ attrs, on }">
      <slot :attrs="attrs" :on="on" name="activator" />
    </template>
    <template #display>
      <v-form
        v-if="showItem"
        @submit.prevent.stop="submitCommunities()"
        ref="form-document"
        lazy-validation
      >
        <v-card flat height="250px">
          <v-text-field
            v-model="communityModel.name"
            :rules="[(v) => !!v || 'Name is required']"
            placeholder="Community Name"
            required
          ></v-text-field>
        </v-card>
      </v-form>
    </template>
    <template #default>
      <generic-alert ref="g_alert" />
    </template>
  </generic-dialog>
</template>

<script>
import EventBus from "../plugins/event-bus";
import GenericAlert from "../components/GenericAlert.vue";
import GenericDialog from "../components/GenericDialog.vue";
export default {
  name: "ManageCommunities",
  components: { GenericDialog, GenericAlert },
  data: () => ({
    //////////////////////
    listLabel: "Communities",
    addNewLabel: "Add Community",
    closeLabel: "Close",
    saveLabel: "Save",
    headers: [
      {
        text: "Name",
        value: "name",
      },

      { text: "Edit", value: "actions", sortable: false },
    ],
    //////////////////////
    loading: false,
    deleting: false,
    communityModel: null,
  }),
  computed: {
    user_communities() {
      return this.$store.getters["community/user_communities"].filter(
        (x) => x.is_admin
      );
    },
    showItem() {
      return !!this.communityModel;
    },
    showDeleteButton() {
      return !!(this.communityModel && this.communityModel.id);
    },
    title() {
      if (!this.communityModel) return "Manage Communities";
      else if (this.communityModel.id) return "Edit Community";
      else return "Add New Community";
    },
  },
  watch: {
  
  },
  mounted() {},
  async created() {},
  methods: {
    exitDisplay() {
      if (this.showItem) {
        this.communityModel = null;
      } else {
        EventBus.$emit("generic-dialog-state", false);
      }
    },
    viewCommunity(item) {
      console.log("item", item);
      if (item) {
        this.communityModel = { ...item };
      } else {
        this.communityModel = {
          id: null,
          name: null,
        };
      }
    },
    async submitCommunities() {
      try {
        this.loading = true;
        const { name: community_name } = this.communityModel;
        const params = {
          community_name,
        };
        await this.$store.dispatch("community/setupCommunity", params);
        this.loading = false;
        this.communityModel = null;
      } catch (err) {
        console.error("submitCommunities err", err);
        this.$refs.g_alert.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
