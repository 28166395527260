import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
//import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const store = { namespaced: true };

// State ======================
store.state = {
  skill: null,
  skills: [],
};

// Getters ====================
store.getters = {};

// Mutations ==================
store.mutations = {
  SET_SKILL(state, skill) {
    state.skill = skill;
  },
  SET_SKILLS(state, skills) {
    state.skills = skills;
  },
};

// Actions ====================
store.actions = {
  //get communityMeta
  async getSkill({ commit, rootGetters }, id) {
    const community_id = rootGetters["community/community"]?.id;
    const {
      data: { getSkill: skill },
    } = await API.graphql(
      graphqlOperation(queries.getSkill, {
        community_id,
        id,
      })
    );
    commit("SET_SKILL", skill);
  },

  async listSkills({ commit, rootGetters }, search) {
    search = (search || "").trim();
    const community_id = rootGetters["community/community"]?.id;
    const {
      data: { listSkills: skills },
    } = await API.graphql(
      graphqlOperation(queries.listSkills, {
        community_id,
        search,
      })
    );
    console.log("skills", skills);
    commit("SET_SKILLS", skills);
  },

  async addSkill({ commit, state, rootGetters }, { description, name }) {
    const community_id = rootGetters["community/community"]?.id;
    const {
      data: { addSkill: skill },
    } = await API.graphql(
      graphqlOperation(mutations.addSkill, {
        community_id,
        name,
        description,
      })
    );
    const { skills } = state;
    skills.push(skill);
    commit("SET_SKILLS", skills);
  },

  async updateSkill({ commit, state, rootGetters }, { id, description, name }) {
    const community_id = rootGetters["community/community"]?.id;
    const {
      data: { updateSkill: skill },
    } = await API.graphql(
      graphqlOperation(mutations.updateSkill, {
        community_id,
        id,
        name,
        description,
      })
    );
    const { skills } = state;
    const idx = skills.findIndex((x) => x.id == skill.id);
    skills.splice(idx, 1, skill);
    commit("SET_SKILLS", skills);
  },

  async deleteSkill({ commit, state, rootGetters }, id) {
    const community_id = rootGetters["community/community"]?.id;
    const {
      data: { deleteSkill: skill },
    } = await API.graphql(
      graphqlOperation(mutations.deleteSkill, {
        community_id,
        id,
      })
    );
    const { skills } = state;
    const idx = skills.findIndex((x) => x.id == skill.id);
    skills.splice(idx, 1);
    commit("SET_SKILLS", skills);
  },
};

// Export =====================
export default store;
