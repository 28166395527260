export const email_regex = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/;
export const phone_regex = /^(\d{5,15}(,\d{5,15})*)?$/;
export const date_regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
export const name_regex = /^[a-z ,.'-]+$/i;

export const fields = [
  {
    key: "id",
    type: "text",
    visible: false,
    importable: false,
    disabled: true,
    label: "ID",
    required: false,
    is_header: true,
  },
  {
    key: "username",
    type: "text",
    visible: true,
    importable: false,
    disabled: true,
    label: "Username",
    required: false,
    is_header: true,
  },
  {
    key: "honorific",
    type: "select",
    visible: true,
    importable: true,
    disabled: false,
    label: "Title",
    required: true,
    is_header: false,
  },
  {
    key: "first_name",
    type: "text",
    visible: true,
    importable: true,
    disabled: false,
    label: "First Name",
    required: true,
    is_header: true,
  },
  {
    key: "last_name",
    type: "text",
    visible: true,
    importable: true,
    disabled: false,
    label: "Last Name",
    required: true,
    is_header: true,
  },
  {
    key: "middle_name",
    type: "text",
    visible: true,
    importable: true,
    disabled: false,
    label: "Middle Name",
    required: false,
    is_header: false,
  },

  {
    key: "nick_name",
    type: "text",
    visible: true,
    importable: false,
    disabled: false,
    label: "Nick Name",
    required: false,
    is_header: false,
  },

  {
    key: "gender",
    type: "select",
    visible: true,
    importable: true,
    disabled: false,
    label: "Gender",
    required: true,
    is_header: false,
  },

  {
    key: "birth_date",
    type: "date",
    visible: true,
    importable: false,
    disabled: false,
    label: "Birth Date",
    required: true,
    is_header: false,
  },

  {
    key: "profession",
    type: "select",
    visible: true,
    importable: false,
    disabled: false,
    label: "Profession",
    required: false,
    is_header: false,
  },

  {
    key: "date_from",
    type: "date",
    visible: true,
    importable: true,
    disabled: false,
    label: "Date From",
    required: true,
    is_header: false,
  },
  {
    key: "date_to",
    type: "date",
    visible: true,
    importable: true,
    disabled: false,
    label: "Date To",
    required: true,
    is_header: false,
  },

  {
    key: "groups",
    type: "multiple",
    visible: true,
    label: "Groups",
    required: true,
    is_header: false,
  },
  {
    key: "skills",
    type: "multiple",
    visible: true,
    importable: false,
    disabled: false,
    label: "Skills",
    required: false,
    is_header: false,
  },

  {
    key: "emails",
    type: "text",
    visible: true,
    importable: true,
    disabled: false,
    label: "Email Address(es)",
    required: true,
    is_header: false,
  },
  {
    key: "phones",
    type: "tel",
    visible: true,
    importable: true,
    disabled: false,
    label: "Phone Number(s)",
    required: false,
    is_header: false,
  },

  {
    key: "status",
    type: "text",
    visible: true,
    importable: false,
    disabled: true,
    label: "Status",
    required: false,
    is_header: true,
  },
];

export const headers = [...fields]
  .filter((x) => x.is_header)
  .map(({ label: text, key: value }) => ({ text, value }))
  .map(({ value, text }) => {
    if (value == "id")
      return { value, text: "No", align: "start", sortable: false };
    else return { value, text };
  });

export const import_fields = [...fields]
  .filter(({ importable }) => !!importable)
  .map(({ key: name, type, required }) => ({
    name,
    type,
    required,
  }));
