<template>
  <v-row justify="center" no-gutters class="mt--6">
    <v-col style="text-align: center">
      Developed and Powered By<br />
      <a href="https://courserv.com" target="_blank">Courserv Technologies</a>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "FooterContactDetails",
};
</script>