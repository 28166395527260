<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <!--identity_token -->
    <v-card v-if="value === 'identity_token'">
      <v-card-title>{{
        `Confirm ${identity_type == "email" ? "Email" : "Mobile"}`
      }}</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="confirmAttributeToken()"
          ref="form-identity-token"
          autocomplete="off"
        >
          <v-text-field
            v-model="identityToken"
            type="text"
            label="Verification Code"
            required
            autocomplete="off"
            :rules="confirmationRules"
          ></v-text-field>
          <button ref="identity-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn @click="$refs['identity-submit'].click()" :loading="loading"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- verify identity -->
    <v-card v-if="value === 'identity'">
      <v-card-title>Identities</v-card-title>

      <v-expansion-panels v-model="identity_type_index">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-chip
              v-if="!!user.email"
              class="ma-2"
              :input-value="user.email_verified"
              label
              :color="user.email_verified ? 'success' : 'warning'"
            >
              <v-avatar left>
                <v-icon>{{
                  user.email_verified
                    ? "mdi-checkbox-marked-circle"
                    : "mdi-minus"
                }}</v-icon>
              </v-avatar>
              Email: {{ user.email }}&nbsp; ({{
                user.email_verified ? "Verified" : "Not Verified"
              }})</v-chip
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <portal-target v-if="identity_type_index == 0" name="identityForm">
            </portal-target>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-chip
              v-if="!!user.phone_number"
              class="ma-2"
              label
              :color="user.phone_number_verified ? 'success' : 'warning'"
            >
              <v-avatar left>
                <v-icon>{{
                  user.phone_number_verified
                    ? "mdi-checkbox-marked-circle"
                    : "mdi-minus"
                }}</v-icon>
              </v-avatar>
              Mobile: {{ user.phone_number }}&nbsp;({{
                user.phone_number_verified ? "Verified" : "Not Verified"
              }})</v-chip
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <portal-target v-if="identity_type_index == 1" name="identityForm">
            </portal-target>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <portal to="identityForm">
        <v-form
          ref="form-identities"
          @submit.prevent.stop="changeIdentity()"
          autocomplete="off"
          class="mx-6"
        >
          <v-radio-group v-model="edit_type" required row>
            <v-radio label="Change" value="change"></v-radio>
            <v-radio
              :disabled="isVerified"
              label="Verify"
              value="verify"
            ></v-radio>
          </v-radio-group>

          <v-text-field
            v-if="identity_type == 'email'"
            v-model="email"
            type="text"
            label="Email"
            required
            :rules="emailRules"
            :readonly="edit_type != 'change'"
          >
          </v-text-field>
          <v-text-field
            v-if="identity_type == 'phone_number'"
            v-model="phone_number"
            type="text"
            label="Mobile"
            required
            :rules="phoneRules"
            :readonly="edit_type != 'change'"
          >
          </v-text-field>
          <v-text-field
            v-if="!!identity_type"
            v-model="password"
            type="password"
            label="Password"
            required
            autocomplete="off"
            :rules="passwordRules"
          ></v-text-field>

          <v-btn
            v-if="!!identity_type"
            :disabled="!this.edit_type"
            type="submit"
            :loading="loading"
            >{{ identityAction }}</v-btn
          >
        </v-form>
      </portal>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Change Password -->
    <v-card v-if="value === 'change-password'">
      <v-card-title>Change Password</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="changePassword()"
          ref="form-change-password"
          autocomplete="off"
        >
          <v-text-field
            v-model="old_password"
            type="password"
            label="Current Password"
            required
            autocomplete="off"
            :rules="[(v) => !!v || 'Current Password is required']"
          ></v-text-field>
          <v-text-field
            v-model="new_password"
            type="password"
            label="New Password"
            required
            autocomplete="off"
            :rules="passwordRules"
          ></v-text-field>
          <button ref="change-password-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn
          @click="$refs['change-password-submit'].click()"
          :loading="loading"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- signup -->

    <v-card v-if="value === 'profile'">
      <v-card-title>Profile</v-card-title>

      <v-card-text>
        <v-form
          @submit.prevent.stop="updateUserAttributes()"
          ref="form-profile"
          autocomplete="off"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :value="user.username"
                label="Username"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                :value="user.email"
                label="Email"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="profileForm.given_name"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="profileForm.family_name"
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="profileForm.gender"
                :items="['Male', 'Female']"
                label="Gender"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="profileForm.birthdate"
                type="date"
                label="Date of Birth"
              ></v-text-field>
            </v-col>
          </v-row>

          <form-address
            v-model="profileForm.address"
            :countries="countries"
          ></form-address>
          <button ref="profile-submit" v-show="false">Submit</button>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('input', null)"> Cancel </v-btn>
        <v-btn @click="$refs['profile-submit'].click()" :loading="loading"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
    <generic-alert ref="g_alert" />
  </v-dialog>
</template>

<script>
import FormAddress from "@/components/FormAddress";
import GenericAlert from "./GenericAlert.vue";
// Dependencies ===============
const { getData } = require("country-list");
// Core =======================
const currentYear = new Date().getFullYear();
//console.log("countries", getData());

const valid_attributes = [
  "given_name",
  "family_name",
  "gender",
  "birthdate",
  "address",
];

//strong password rules
const strongPasswordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

export default {
  name: "Account",
  components: { FormAddress, GenericAlert },
  props: ["value"],

  data: () => ({
    profileForm: {},
    dialog: false,
    email: null,
    phone_number: "",
    password: "",
    identityToken: null,

    identity_type_index: null,
    edit_type: null,
    old_password: "",
    new_password: "",

    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && strongPasswordPattern.test(v)) ||
        "Password must be at least 8 characters with at least one Uppercase, one lowercase, one number and one special character",
    ],

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /\S+@\S+\.\S+/.test(v) || "E-mail must be valid",
    ],

    phoneRules: [
      (v) => !!v || "Mobile Number is required",
      (v) =>
        /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/.test(v) ||
        "Mobile must be valid",
    ],

    yearRules: [
      (v) => !!v || "Year is required",
      (v) => /^(19|20)\d{2}$/.test(v) || "Year must be valid",
      (v) => v < currentYear - 8 || "You must be older than 12",
    ],
    confirmationRules: [
      (v) => !!v || "Verification code is required",
      (v) => (v && v.length >= 5) || "Invalid Verification code",
    ],
  }),
  computed: {
    identity_type() {
      switch (this.identity_type_index) {
        case 0:
          return "email";
        case 1:
          return "phone_number";
        default:
          return null;
      }
    },
    isVerified() {
      if (this.identity_type === "phone_number")
        return this.user.phone_number_verified;
      else if (this.identity_type == "email") return this.user.email_verified;
      else return false;
    },
    countries() {
      const countries = getData();
      countries.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return countries;
    },
    user() {
      return this.$store.getters["account/attributes"];
    },
    identityAction() {
      return `${this.edit_type || "?"} ${
        this.identity_type == "email" ? "Email" : "Mobile"
      }`;
    },
    loading() {
      return this.$store.state.account.loading;
    },
  },
  watch: {
    value: function (_type) {
      this.dialog = !!_type;
      switch (_type) {
        case "profile":
          {
            for (let key of valid_attributes) {
              this.$set(this.profileForm, key, this.user[key]);
            }
          }
          break;
        case "identity": {
          console.log("identity watching");
          this.phone_number = this.user.phone_number;
          this.email = this.user.email;
          this.password = null;
          this.identity_type_index = null;
          this.edit_type = null;
          break;
        }
      }
    },

    edit_type: function () {
      this.phone_number = this.user.phone_number;
      this.email = this.user.email;
    },

    identity_type_index: function () {
      this.phone_number = this.user.phone_number;
      this.email = this.user.email;
      this.edit_type = null;
    },

    "$store.state.account.error": function (err) {
      console.log("error", err);
      this.$refs.g_alert.setGraphQlError(err);
    },
    "$store.state.account.action_completed": function (_action_completed) {
      if (_action_completed) {
        this.$emit("input", null);
      }
    },
    "$store.state.account.identity_token": function (val) {
      if (val) {
        this.$emit("input", "identity_token");
      }
    },
  },
  mounted() {},
  async created() {},
  methods: {
    changePassword() {
      if (!this.$refs[`form-change-password`].validate()) return;
      const { old_password, new_password } = this;
      this.$store.dispatch("account/changePassword", {
        old_password,
        new_password,
      });
    },
    changeIdentity() {
      if (!this.$refs[`form-identities`].validate()) return;
      const type = this.identity_type;

      const { password } = this;
      if (this.edit_type == "change") {
        const attributes = {};
        attributes[type] = this[type];
        console.log("attributes", attributes);
        const passwordRequired = true;
        const params = { attributes, password, passwordRequired };
        this.$store.dispatch("account/updateUserAttributes", params);
      } else {
        const attribute = this.identity_type;
        const params = { attribute, password };
        this.$store.dispatch("account/verifyAttribute", params);
      }
    },

    confirmAttributeToken() {
      if (!this.$refs["form-identity-token"].validate()) return;
      const { identityToken: token, identity_type: attribute } = this;
      this.$store.dispatch("account/confirmAttributeToken", {
        token,
        attribute,
      });
    },

    updateUserAttributes() {
      if (!this.$refs["form-profile"].validate()) return;
      this.$store.dispatch("account/updateUserAttributes", {
        ...this.profileForm,
      });
    },
  },
};
</script>
