<template>
  <v-app>
    <SideBar
      @change-page="newPage = $event"
      @change-src="newDocument = $event"
    ></SideBar>

    <v-app-bar app extension-height="100">
      <v-app-bar-nav-icon
        v-if="authorized"
        @click.stop="toggleDrawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        {{ communityTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu offset-y v-if="authorized">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon> {{ (user || {}).username }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in accountItems"
            :key="index"
            @click="item['action']()"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <Fragment v-else>
        <v-btn outlined @click="triggerMembership(true)"> Sign Up </v-btn>
        <v-btn plain @click="triggerMembership()"> Sign In </v-btn>
      </Fragment>

      <template v-slot:extension v-if="appType == 'mem'">
        <v-container fluid class="mt-n0">
          <portal-target name="application-control"> </portal-target>
        </v-container>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <default v-if="appType == 'def'" />
        <membership v-if="authorized && appType == 'mem'" />
      </v-container>
      <account-member v-model="memberType" />
      <account-anonymous v-model="anonymousType" />
    </v-main>
    <generic-alert ref="g_alert" />
    <v-footer app>
      <v-container>
        <footer-contact-details />
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { Fragment } from "vue-frag";

import AccountAnonymous from "../components/AccountAnonymous.vue";
import AccountMember from "../components/AccountMember.vue";
import SideBar from "../components/SideBar.vue";
import FooterContactDetails from "../components/FooterContactDetails.vue";
import GenericAlert from "../components/GenericAlert.vue";
import Default from "../views/Default.vue";
import Membership from "../views/Membership.vue";
import EventBus from "../plugins/event-bus";

export default {
  name: "App",

  components: {
    Fragment,
    AccountAnonymous,
    AccountMember,
    SideBar,
    FooterContactDetails,
    GenericAlert,
    Default,
    Membership,
  },

  data() {
    const { setMemberType, signOut } = this;
    return {
      memberType: null,
      anonymousType: null,
      loading: false,
      appType: null,
      accountItems: [
        { title: "Profile", action: () => setMemberType("profile") },
        {
          title: "Change Password",
          action: () => setMemberType("change-password"),
        },
        {
          title: "Logout",
          action: () => signOut(),
        },
      ],
    };
  },

  computed: {
    isExtendedMenu() {
      return this.appType == "mem";
    },
    appTitle() {
      switch (this.appType) {
        case "mem":
          return "Membership";

        default:
          return "";
      }
    },

    community() {
      return this.$store.getters["community/community"];
    },
    communityTitle() {
      return this.$store.getters["community/communityTitle"];
    },
    authorized() {
      return this.$store.getters["account/authorized"];
    },
    user() {
      return this.$store.state.account.user;
    },
  },
  mounted() {
    EventBus.$on("app-selector", (_type = null) => {
      this.appType = _type;
    });
  },
  watch: {
    authorized: function (_authorized) {
      console.log("_authorized", _authorized);
      this.anonymousType = null;
      this.memberType = null;
    },
  },
  methods: {
    setMemberType(type) {
      this.memberType = type;
    },
    triggerMembership(isSignUp = false) {
      this.anonymousType = isSignUp ? "signup" : "signin";
    },
    toggleDrawer() {
      EventBus.$emit("toggle-drawer");
    },
    signOut() {
      this.$store.dispatch("account/logout");
    },
  },
};
</script>

<style scoped>
/*deep*/
div.v-toolbar__extension {
  background-color: green;
}
</style>