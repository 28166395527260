<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="addressForm.address1"
        label="Address 1"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="addressForm.address2"
        label="Address 2"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="addressForm.city" label="City"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="addressForm.state" label="State"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-select
        v-model="addressForm.country"
        :items="countries"
        label="Country"
        item-value="code"
        item-text="name"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
const addressFields = ["address1", "address2", "city", "state", "country"];
export default {
  data() {
    return {
      addressForm: {},
    };
  },
  props: ["value", "countries"],
  watch: {
    addressForm: {
      handler: function (val) {
        this.$emit("input", JSON.stringify(val));
      },
      deep: true,
    },
  },
  mounted() {
    this.formatAddress(this.value);
  },
  methods: {
    formatAddress(strValue) {
      try {
        const objValue = JSON.parse(strValue);
        for (let field of addressFields) {
          this.$set(this.addressForm, field, objValue[field]);
        }

        const saveCode = this.addressForm.country;
        const selectedCountry = this.countries.find(
          (x) => x.name === saveCode || x.code === saveCode
        );
        if (selectedCountry) {
          this.$set(this.addressForm, "country", selectedCountry.code);
        } else {
          this.$set(this.addressForm, "country", null);
        }
      } catch (err) {
        for (let field of addressFields) {
          this.$set(this.addressForm, field, null);
        }
      }
    },
  },
};
</script>