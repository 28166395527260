<template>
  <v-row no-gutters>
    <v-col v-if="withLabel" cols="6"
      >{{ label | capitalize
      }}<span v-if="isMandatory" style="color: red">*</span></v-col
    >
    <v-col :cols="withLabel ? 2 : 12" class="text-center"
      ><v-btn icon :disabled="value <= min" @click="$emit('input', value - 1)"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >

      <span v-if="isHex">{{ value | convertToAlpha }}</span>
      <span v-else>{{ value }}</span>
      <v-btn icon :disabled="value >= max" @click="$emit('input', value + 1)"
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      ></v-col
    >
  </v-row>
</template>
<script>
import { capitalCase } from "change-case";
export default {
  name: "HexaSpinner",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: -1,
    },
    min: {
      type: Number,
      default: -1,
    },
    max: {
      type: Number,
      default: 1000,
    },
    isHex: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    convertToAlpha(val) {
      return val > -1 ? String.fromCharCode(val + 65) : "";
    },
    capitalize(x) {
      return capitalCase(x);
    },
  },
};
</script>